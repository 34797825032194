<script setup>
import { toRefs } from 'vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import xClose from '@/assets/icons/x-close.svg'
import bookmarkOutline from '@/assets/icons/bookmark-outline.svg'
import bookmarkSolid from '@/assets/icons/bookmark-solid.svg'

const props = defineProps({
  open: Boolean,
  saved: Boolean,
  src: String,
})

const emit = defineEmits(['update:open', 'update:saved'])

const { open, src, saved } = toRefs(props)

function close() {
  emit('update:open', false)
}

function save() {
  emit('update:saved', !saved.value)
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-sm transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex h-full min-h-full justify-center text-center md:p-0 items-center"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
          >
            <DialogPanel
              class="relative transform overflow-hidden text-left shadow-xl transition-all h-auto max-w-[95vw]"
            >
              <div class="absolute top-3 right-3 flex flex-col gap-3">
                <button
                  type="button"
                  class="relative rounded-full focus:outline-none bg-[#11152D]"
                  @click="close"
                  style="
                    box-shadow:
                      0px 0px 0px 1.2px #1e1a3d,
                      0px 0px 12px 2.4px #13142e inset;
                  "
                >
                  <img
                    class="h-8 w-8 z-1 p-1.5"
                    :src="xClose"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  class="relative rounded-full focus:outline-none bg-[#11152D]"
                  @click="save"
                  style="
                    box-shadow:
                      0px 0px 0px 1.2px #1e1a3d,
                      0px 0px 12px 2.4px #13142e inset;
                  "
                >
                  <img
                    v-if="!saved"
                    :src="bookmarkOutline"
                    class="h-8 w-8 text-white z-1 p-1.5"
                    aria-hidden="true"
                  />
                  <img
                    v-else
                    :src="bookmarkSolid"
                    class="h-8 w-8 text-white z-1 p-1.5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <img
                :src="src"
                class="max-h-[90vh] rounded-lg"
                style="user-select: none; user-drag: none; pointer-events: none"
                draggable="false"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
