import eeaPlusCountries from '@/assets/data/eea_plus_countries.json'
import { useCreateModelStore } from '@/stores/createModel'
import store from '@/stores/index.js'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import CompleteRegister from '@/views/auth/CompleteRegister.vue'
import Chat from '@/views/Chat/Chat.vue'
import CreateModel from '@/views/CreateModel/CreateModel.vue'
import Generator from '@/views/Generator.vue'
import Home from '@/views/Home/Home.vue'
import LandingPageOne from '@/views/LandingPage/One.vue'
import ModelDetails from '@/views/ModelDetails.vue'
import PurchaseComplete from '@/views/PurchaseComplete.vue'
import Rater from '@/views/Rater.vue'
import ThreeDSecureRedirect from '@/views/ThreeDSecureRedirect.vue'
import ViewModel from '@/views/ViewModel.vue'
import axios from 'axios'
import premadeModels from 'common/lib/premade.js'

export default [
  {
    path: '/',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/ai-boyfriend',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/ai-hentai',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/anime-ai-girlfriend',
    redirect: '/ai-hentai',
  },
  {
    path: '/anime-ai-boyfriend',
    redirect: '/ai-hentai',
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/complete-register',
    component: CompleteRegister,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/rater',
    component: Rater,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/ai-girlfriend/:modelName',
    component: ModelDetails,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.gender == 'female',
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  {
    path: '/ai-boyfriend/:modelName',
    component: ModelDetails,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.gender == 'male',
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  { path: '/image-generator', redirect: '/generator' },
  {
    path: '/generator',
    component: Generator,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },
  { path: '/app', redirect: '/chat' },
  {
    path: '/chat',
    component: Chat,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/settings',
    component: () => import('@/views/Account/Account.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/saved',
    component: () => import('@/views/Saved.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/subscribe',
    component: () => import('@/views/Subscribe.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user?.subscription) {
        next({ path: '/settings', query: { tab: 'subscription' } })
      } else next()
    },
  },
  {
    path: '/create-model',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const { gender, style, ...otherQueryParams } = to.query

      if (gender === 'female' || gender === 'male') {
        if (style === 'realistic' || style === 'anime') {
          return next({
            path: '/create-model/2',
            query: { ...otherQueryParams, gender, style },
          })
        }

        return next({
          path: '/create-model/1',
          query: { ...otherQueryParams, gender },
        })
      }

      return next({ path: '/create-model/0', query: otherQueryParams })
    },
  },
  {
    path: '/create-girlfriend',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-boyfriend',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-model/:n',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      // Check if 'n' is an integer
      const n = parseInt(to.params.n, 10)
      if (isNaN(n)) {
        // If 'n' is not an integer, cancel the navigation
        next(false)
      } else {
        // If 'n' is an integer, add it as an integer to route params and proceed
        to.params.n = n
        next()
      }
    },
  },
  {
    path: '/view-model',
    component: ViewModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const { pendingModel } = useCreateModelStore()

      if (!pendingModel) {
        return next({ path: '/create-model' })
      }

      return next()
    },
  },
  {
    path: '/buy-luna',
    component: () => import('@/views/BuyLuna/BuyLuna.vue'),
  },
  {
    path: '/purchase-complete',
    component: PurchaseComplete,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/threeds-redirect',
    component: ThreeDSecureRedirect,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/policies/PrivacyPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/cancellation-policy',
    component: () => import('@/views/policies/CancellationPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service-eea',
    component: () => import('@/views/policies/TermsOfServiceEEA.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service-row',
    component: () => import('@/views/policies/TermsOfServiceROW.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service',
    // for some reason beforeEneter guard is not working without component part here, so I added a dummy component.
    component: {
      template:
        '<div>Redirecting to the appropriate terms of service page...</div>',
    },
    meta: {
      hasSideBar: true,
    },
    beforeEnter: async (to, from, next) => {
      let country = ''

      if (store.state.country) {
        country = store.state.country
      } else {
        if (store.state.user) {
          country = store.state.user?.country
        } else {
          const resp = await axios.get(
            import.meta.env.VITE_BASE_URL + '/location/country',
          )
          country = resp.data.country
          store.commit('SET_COUNTRY', { country: country })
        }
      }

      function isCountryInEEA(countryName) {
        return eeaPlusCountries.some(
          (country) => country.name.toLowerCase() === countryName.toLowerCase(),
        )
      }

      if (country && isCountryInEEA(country)) {
        next({ path: '/terms-of-service-eea' })
      }
      next({ path: '/terms-of-service-row' })
    },
  },
  {
    path: '/lp/ai-girlfriend-search',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Girlfriend',
      headline: `Experience an <br class="md:hidden" /> <span class="text-purple-500">AI Girlfriend</span> App<br />That Actually Works`,
      ctaPhrase: 'Create my AI Girlfriend',
      warningText:
        'The AI Girlfriends will send pictures upon request, regardless of the nature of the request. Please use responsibly.',
      gender: 'female',
      faqs: [
        {
          question: 'How does an AI girlfriend work?',
          answer:
            'An AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.',
          open: false,
        },
        {
          question: "Can I customize my AI girlfriend's appearance?",
          answer:
            'Yes, you can! You have the option to create a custom AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.',
          open: false,
        },
        {
          question: 'Can I teach my AI girlfriend new things?',
          answer:
            'Absolutely! Your AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.',
          open: false,
        },
        {
          question: 'Can I have multiple AI girlfriends at once?',
          answer:
            'Yes, our platform allows you to create and interact with multiple AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.',
          open: false,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
          open: false,
        },
        {
          question: 'Are my conversations private?',
          answer:
            'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data.',
        },
      ],
    },
  },
  {
    path: '/lp/ai-girlfriend',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Girlfriend',
      headline: `Explore your Deepest Fantasies<br /> with an <span class="text-purple-500">AI Girlfriend</span>`,
      ctaPhrase: 'Create my AI Girlfriend',
      warningText:
        'The AI Girlfriends will send pictures upon request, regardless of the nature of the request. Please use responsibly.',
      gender: 'female',
      faqs: [
        {
          question: 'How does an AI girlfriend work?',
          answer:
            'An AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.',
          open: false,
        },
        {
          question: "Can I customize my AI girlfriend's appearance?",
          answer:
            'Yes, you can! You have the option to create a custom AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.',
          open: false,
        },
        {
          question: 'Can I teach my AI girlfriend new things?',
          answer:
            'Absolutely! Your AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.',
          open: false,
        },
        {
          question: 'Can I have multiple AI girlfriends at once?',
          answer:
            'Yes, our platform allows you to create and interact with multiple AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.',
          open: false,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
          open: false,
        },
        {
          question: 'Are my conversations private?',
          answer:
            'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data.',
        },
      ],
    },
  },
  {
    path: '/lp/ai-boyfriend-search',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Boyfriend',
      headline: `Experience an <br class="md:hidden" /> <span class="text-purple-500">AI Boyfriend</span> App<br />That Actually Works`,
      ctaPhrase: 'Create my AI Boyfriend',
      warningText:
        'The AI Boyfriends will send pictures upon request, regardless of the nature of the request. Please use responsibly.',
      gender: 'male',
      faqs: [
        {
          id: 'faq1',
          question: 'How does an AI boyfriend work?',
          answer:
            'An AI boyfriend is an advanced virtual partner built with state-of-the-art artificial intelligence. This digital companion can chat with you, share images, and engage in lifelike conversations. Using machine learning, the AI learns about your preferences and adapts to your communication style over time, creating a personalized and immersive experience.',
          open: false,
        },
        {
          id: 'faq2',
          question: 'Is the AI boyfriend experience realistic?',
          answer:
            'We aim to make the AI boyfriend experience as lifelike as possible. Our AI chat algorithm simulates human conversation patterns and emotional responses, evolving through your interactions to develop a dynamic and realistic relationship.',
          open: false,
        },
        {
          id: 'faq3',
          question: 'How can I communicate with my AI boyfriend?',
          answer:
            'You can talk to your AI boyfriend via text messages, voice messages, and even by exchanging pictures. Our platform is designed to be user-friendly and intuitive, ensuring smooth and natural communication.',
          open: false,
        },
        {
          id: 'faq4',
          question: "Can I customize my AI boyfriend's personality?",
          answer:
            "Yes, you can customize your AI boyfriend's personality. During the setup, you can select various personality traits and characteristics to match your preferences. This ensures a unique and tailored experience. Additionally, there's an adaptive personality option, allowing your AI boyfriend to evolve and adjust to your preferences over time.",
          open: false,
        },
        {
          id: 'faq5',
          question: 'Can my AI boyfriend send pictures?',
          answer:
            'Absolutely! Your AI boyfriend can send a variety of pictures, from casual snaps to more intimate images, enhancing the personalization and realism of your interactions.',
          open: false,
        },
        {
          id: 'faq6',
          question: 'Is my privacy protected?',
          answer:
            'Your privacy is our top priority. We guarantee that all interactions with your AI boyfriend are kept private and secure. We employ advanced security measures to safeguard your data, and you do not need to provide any personal information to use our service.',
          open: false,
        },
      ],
    },
  },
  {
    path: '/lp/18/ai-girlfriend',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Girlfriend',
      headline: `Explore your Deepest Fantasies<br /> with an <span class="text-purple-500">AI Girlfriend</span>`,
      ctaPhrase: 'Create my AI Girlfriend',
      warningText:
        'The AI Girlfriends will send pictures upon request, regardless of the nature of the request. Please use responsibly.',
      gender: 'female',
      version18: true,
      faqs: [
        {
          question: 'How does an AI girlfriend work?',
          answer:
            'An AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.',
          open: false,
        },
        {
          question: "Can I customize my AI girlfriend's appearance?",
          answer:
            'Yes, you can! You have the option to create a custom AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.',
          open: false,
        },
        {
          question: 'Can I teach my AI girlfriend new things?',
          answer:
            'Absolutely! Your AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.',
          open: false,
        },
        {
          question: 'Can I have multiple AI girlfriends at once?',
          answer:
            'Yes, our platform allows you to create and interact with multiple AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.',
          open: false,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
          open: false,
        },
        {
          question: 'Are my conversations private?',
          answer:
            'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data.',
        },
      ],
    },
  },
  {
    path: '/affiliate',
    component: () => import('@/views/Affiliate/Affiliate.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
]
