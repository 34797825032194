import cdnFile from '@/helpers/cdnFile'
import hearts from '@/assets/icons/hearts.svg'
import angel from '@/assets/icons/angel.svg'
import flameAlt from '@/assets/icons/flame-alt.svg'
import crown from '@/assets/icons/crown.svg'
import snowflake from '@/assets/icons/snowflake.svg'
import submissive from '@/assets/icons/submissive.svg'
import tooth from '@/assets/icons/tooth.svg'
import bookOpen from '@/assets/icons/book-open.svg'
import brush from '@/assets/icons/brush.svg'
import star from '@/assets/icons/star.svg'
import atom from '@/assets/icons/atom.svg'
import pencilLine from '@/assets/icons/pencil-line.svg'
import glasses from '@/assets/icons/glasses.svg'
import flame from '@/assets/icons/flame.svg'
import scale from '@/assets/icons/scale.svg'
import cyclist from '@/assets/icons/cyclist.svg'
import blog from '@/assets/icons/blog.svg'
import mountain from '@/assets/icons/mountain.svg'
import gamingPad from '@/assets/icons/gaming-pad.svg'
import baloons from '@/assets/icons/baloons.svg'
import camera from '@/assets/icons/camera.svg'
import hands from '@/assets/icons/hands.svg'
import car from '@/assets/icons/car.svg'
import masks from '@/assets/icons/masks.svg'
import badBoy from '@/assets/icons/bad-boy.svg'
import protective from '@/assets/icons/protective.svg'
import caring from '@/assets/icons/caring.svg'
import flagUs from '@/assets/icons/flags/us.svg'
import flagGb from '@/assets/icons/flags/gb.svg'
import flagIn from '@/assets/icons/flags/in.svg'
import flagAfrica from '@/assets/icons/flags/africa.svg'
import flagAu from '@/assets/icons/flags/au.svg'
import flagFr from '@/assets/icons/flags/fr.svg'

// Gender images
import femaleAvif from '@/assets/img/create-model/gender/female.avif'
import femaleWebp from '@/assets/img/create-model/gender/female.webp'
import maleAvif from '@/assets/img/create-model/gender/male.avif'
import maleWebp from '@/assets/img/create-model/gender/male.webp'

// Female Realistic Style
import femaleRealisticAvif from '@/assets/img/create-model/female/styles/realistic.avif'
import femaleRealisticWebp from '@/assets/img/create-model/female/styles/realistic.webp'
import femaleAnimeAvif from '@/assets/img/create-model/female/styles/anime.avif'
import femaleAnimeWebp from '@/assets/img/create-model/female/styles/anime.webp'

// Female Ethnicities
import femaleCaucasianAvif from '@/assets/img/create-model/female/ethnicities/caucasian.avif'
import femaleCaucasianWebp from '@/assets/img/create-model/female/ethnicities/caucasian.webp'
import femaleAsianAvif from '@/assets/img/create-model/female/ethnicities/asian.avif'
import femaleAsianWebp from '@/assets/img/create-model/female/ethnicities/asian.webp'
import femaleArabicAvif from '@/assets/img/create-model/female/ethnicities/arabic.avif'
import femaleArabicWebp from '@/assets/img/create-model/female/ethnicities/arabic.webp'
import femaleIndianAvif from '@/assets/img/create-model/female/ethnicities/hindi.avif'
import femaleIndianWebp from '@/assets/img/create-model/female/ethnicities/hindi.webp'
import femaleLatinaAvif from '@/assets/img/create-model/female/ethnicities/latina.avif'
import femaleLatinaWebp from '@/assets/img/create-model/female/ethnicities/latina.webp'
import femaleAfricanAvif from '@/assets/img/create-model/female/ethnicities/african.avif'
import femaleAfricanWebp from '@/assets/img/create-model/female/ethnicities/african.webp'

// Female Ages
import femaleTwentiesAvif from '@/assets/img/create-model/female/ages/twenties.avif'
import femaleTwentiesWebp from '@/assets/img/create-model/female/ages/twenties.webp'
import femaleThirtiesAvif from '@/assets/img/create-model/female/ages/thirties.avif'
import femaleThirtiesWebp from '@/assets/img/create-model/female/ages/thirties.webp'
import femaleFortiesAvif from '@/assets/img/create-model/female/ages/fourties.avif'
import femaleFortiesWebp from '@/assets/img/create-model/female/ages/fourties.webp'
import femaleFiftiesAvif from '@/assets/img/create-model/female/ages/fifties.avif'
import femaleFiftiesWebp from '@/assets/img/create-model/female/ages/fifties.webp'

// Eye Colors
import femaleBlackEyeAvif from '@/assets/img/create-model/female/eye-colors/black.avif'
import femaleBlackEyeWebp from '@/assets/img/create-model/female/eye-colors/black.webp'
import femaleBrownEyeAvif from '@/assets/img/create-model/female/eye-colors/brown.avif'
import femaleBrownEyeWebp from '@/assets/img/create-model/female/eye-colors/brown.webp'
import femaleGrayEyeAvif from '@/assets/img/create-model/female/eye-colors/gray.avif'
import femaleGrayEyeWebp from '@/assets/img/create-model/female/eye-colors/gray.webp'
import femaleBlueEyeAvif from '@/assets/img/create-model/female/eye-colors/blue.avif'
import femaleBlueEyeWebp from '@/assets/img/create-model/female/eye-colors/blue.webp'
import femaleGreenEyeAvif from '@/assets/img/create-model/female/eye-colors/green.avif'
import femaleGreenEyeWebp from '@/assets/img/create-model/female/eye-colors/green.webp'

// Female Hair Styles
import femaleStraightHairAvif from '@/assets/img/create-model/female/hair-styles/straight.avif'
import femaleStraightHairWebp from '@/assets/img/create-model/female/hair-styles/straight.webp'
import femaleCurlyHairAvif from '@/assets/img/create-model/female/hair-styles/curly.avif'
import femaleCurlyHairWebp from '@/assets/img/create-model/female/hair-styles/curly.webp'
import femalePonytailAvif from '@/assets/img/create-model/female/hair-styles/ponytail.avif'
import femalePonytailWebp from '@/assets/img/create-model/female/hair-styles/ponytail.webp'
import femaleBraidsAvif from '@/assets/img/create-model/female/hair-styles/braids.avif'
import femaleBraidsWebp from '@/assets/img/create-model/female/hair-styles/braids.webp'

// Hair Lengths
import femaleLongHairAvif from '@/assets/img/create-model/female/hair-lengths/long.avif'
import femaleLongHairWebp from '@/assets/img/create-model/female/hair-lengths/long.webp'
import femaleShortHairAvif from '@/assets/img/create-model/female/hair-lengths/short.avif'
import femaleShortHairWebp from '@/assets/img/create-model/female/hair-lengths/short.webp'

// Hair Colors
import femaleBrunetteHairAvif from '@/assets/img/create-model/female/hair-colors/brunette.avif'
import femaleBrunetteHairWebp from '@/assets/img/create-model/female/hair-colors/brunette.webp'
import femaleBlackHairAvif from '@/assets/img/create-model/female/hair-colors/black.avif'
import femaleBlackHairWebp from '@/assets/img/create-model/female/hair-colors/black.webp'
import femaleBlondeHairAvif from '@/assets/img/create-model/female/hair-colors/blonde.avif'
import femaleBlondeHairWebp from '@/assets/img/create-model/female/hair-colors/blonde.webp'
import femaleGingerHairAvif from '@/assets/img/create-model/female/hair-colors/ginger.avif'
import femaleGingerHairWebp from '@/assets/img/create-model/female/hair-colors/ginger.webp'
import femalePinkHairAvif from '@/assets/img/create-model/female/hair-colors/pink.avif'
import femalePinkHairWebp from '@/assets/img/create-model/female/hair-colors/pink.webp'
import femalePurpleHairAvif from '@/assets/img/create-model/female/hair-colors/purple.avif'
import femalePurpleHairWebp from '@/assets/img/create-model/female/hair-colors/purple.webp'

// Female Body Types
import femaleSkinnyAvif from '@/assets/img/create-model/female/body-types/skinny.avif'
import femaleSkinnyWebp from '@/assets/img/create-model/female/body-types/skinny.webp'
import femaleSlimAvif from '@/assets/img/create-model/female/body-types/slim.avif'
import femaleSlimWebp from '@/assets/img/create-model/female/body-types/slim.webp'
import femaleAthleticAvif from '@/assets/img/create-model/female/body-types/athletic.avif'
import femaleAthleticWebp from '@/assets/img/create-model/female/body-types/athletic.webp'
import femaleThickAvif from '@/assets/img/create-model/female/body-types/thick.avif'
import femaleThickWebp from '@/assets/img/create-model/female/body-types/thick.webp'

// Breast Sizes
import femaleSmallBreastAvif from '@/assets/img/create-model/female/breast-sizes/small.avif'
import femaleSmallBreastWebp from '@/assets/img/create-model/female/breast-sizes/small.webp'
import femaleAverageBreastAvif from '@/assets/img/create-model/female/breast-sizes/average.avif'
import femaleAverageBreastWebp from '@/assets/img/create-model/female/breast-sizes/average.webp'
import femaleLargeBreastAvif from '@/assets/img/create-model/female/breast-sizes/large.avif'
import femaleLargeBreastWebp from '@/assets/img/create-model/female/breast-sizes/large.webp'
import femaleHugeBreastAvif from '@/assets/img/create-model/female/breast-sizes/huge.avif'
import femaleHugeBreastWebp from '@/assets/img/create-model/female/breast-sizes/huge.webp'

// Butt Sizes
import femaleSmallButtAvif from '@/assets/img/create-model/female/butt-sizes/small.avif'
import femaleSmallButtWebp from '@/assets/img/create-model/female/butt-sizes/small.webp'
import femaleAverageButtAvif from '@/assets/img/create-model/female/butt-sizes/average.avif'
import femaleAverageButtWebp from '@/assets/img/create-model/female/butt-sizes/average.webp'
import femaleLargeButtAvif from '@/assets/img/create-model/female/butt-sizes/large.avif'
import femaleLargeButtWebp from '@/assets/img/create-model/female/butt-sizes/large.webp'
import femaleHugeButtAvif from '@/assets/img/create-model/female/butt-sizes/huge.avif'
import femaleHugeButtWebp from '@/assets/img/create-model/female/butt-sizes/huge.webp'

// Male Realistic Style
import maleRealisticAvif from '@/assets/img/create-model/male/styles/realistic.avif'
import maleRealisticWebp from '@/assets/img/create-model/male/styles/realistic.webp'
import maleAnimeAvif from '@/assets/img/create-model/male/styles/anime.avif'
import maleAnimeWebp from '@/assets/img/create-model/male/styles/anime.webp'

// Male Ethnicities
import maleCaucasianAvif from '@/assets/img/create-model/male/ethnicities/caucasian.avif'
import maleCaucasianWebp from '@/assets/img/create-model/male/ethnicities/caucasian.webp'
import maleAsianAvif from '@/assets/img/create-model/male/ethnicities/asian.avif'
import maleAsianWebp from '@/assets/img/create-model/male/ethnicities/asian.webp'
import maleArabicAvif from '@/assets/img/create-model/male/ethnicities/arabic.avif'
import maleArabicWebp from '@/assets/img/create-model/male/ethnicities/arabic.webp'
import maleIndianAvif from '@/assets/img/create-model/male/ethnicities/hindi.avif'
import maleIndianWebp from '@/assets/img/create-model/male/ethnicities/hindi.webp'
import maleLatinoAvif from '@/assets/img/create-model/male/ethnicities/latino.avif'
import maleLatinoWebp from '@/assets/img/create-model/male/ethnicities/latino.webp'
import maleAfricanAvif from '@/assets/img/create-model/male/ethnicities/african.avif'
import maleAfricanWebp from '@/assets/img/create-model/male/ethnicities/african.webp'

// Male Ages
import maleTwentiesAvif from '@/assets/img/create-model/male/ages/twenties.avif'
import maleTwentiesWebp from '@/assets/img/create-model/male/ages/twenties.webp'
import maleThirtiesAvif from '@/assets/img/create-model/male/ages/thirties.avif'
import maleThirtiesWebp from '@/assets/img/create-model/male/ages/thirties.webp'
import maleFortiesAvif from '@/assets/img/create-model/male/ages/forties.avif'
import maleFortiesWebp from '@/assets/img/create-model/male/ages/forties.webp'
import maleFiftiesAvif from '@/assets/img/create-model/male/ages/fifties.avif'
import maleFiftiesWebp from '@/assets/img/create-model/male/ages/fifties.webp'

// Eye Colors (Male)
import maleBlackEyeAvif from '@/assets/img/create-model/male/eye-colors/black.avif'
import maleBlackEyeWebp from '@/assets/img/create-model/male/eye-colors/black.webp'
import maleBrownEyeAvif from '@/assets/img/create-model/male/eye-colors/brown.avif'
import maleBrownEyeWebp from '@/assets/img/create-model/male/eye-colors/brown.webp'
import maleGrayEyeAvif from '@/assets/img/create-model/male/eye-colors/gray.avif'
import maleGrayEyeWebp from '@/assets/img/create-model/male/eye-colors/gray.webp'
import maleBlueEyeAvif from '@/assets/img/create-model/male/eye-colors/blue.avif'
import maleBlueEyeWebp from '@/assets/img/create-model/male/eye-colors/blue.webp'
import maleGreenEyeAvif from '@/assets/img/create-model/male/eye-colors/green.avif'
import maleGreenEyeWebp from '@/assets/img/create-model/male/eye-colors/green.webp'

// Hair Styles (Male)
import maleStraightHairAvif from '@/assets/img/create-model/male/hair-styles/straight.avif'
import maleStraightHairWebp from '@/assets/img/create-model/male/hair-styles/straight.webp'
import maleCurlyHairAvif from '@/assets/img/create-model/male/hair-styles/curly.avif'
import maleCurlyHairWebp from '@/assets/img/create-model/male/hair-styles/curly.webp'
import maleDreadlocksAvif from '@/assets/img/create-model/male/hair-styles/dreadlocks.avif'
import maleDreadlocksWebp from '@/assets/img/create-model/male/hair-styles/dreadlocks.webp'
import maleBuzzCutAvif from '@/assets/img/create-model/male/hair-styles/buzzCut.avif'
import maleBuzzCutWebp from '@/assets/img/create-model/male/hair-styles/buzzCut.webp'

// Hair Colors (Male)
import maleBrownHairAvif from '@/assets/img/create-model/male/hair-colors/brown.avif'
import maleBrownHairWebp from '@/assets/img/create-model/male/hair-colors/brown.webp'
import maleBlackHairAvif from '@/assets/img/create-model/male/hair-colors/black.avif'
import maleBlackHairWebp from '@/assets/img/create-model/male/hair-colors/black.webp'
import maleBlondeHairAvif from '@/assets/img/create-model/male/hair-colors/blonde.avif'
import maleBlondeHairWebp from '@/assets/img/create-model/male/hair-colors/blonde.webp'
import maleGingerHairAvif from '@/assets/img/create-model/male/hair-colors/ginger.avif'
import maleGingerHairWebp from '@/assets/img/create-model/male/hair-colors/ginger.webp'

// Male Body Types
import maleMuscularAvif from '@/assets/img/create-model/male/body-types/muscular.avif'
import maleMuscularWebp from '@/assets/img/create-model/male/body-types/muscular.webp'
import maleSlimAvif from '@/assets/img/create-model/male/body-types/slim.avif'
import maleSlimWebp from '@/assets/img/create-model/male/body-types/slim.webp'

// Female Anime Ethnicities
import femaleAnimeWhiteAvif from '@/assets/img/create-model/female-anime/ethnicities/white.avif'
import femaleAnimeWhiteWebp from '@/assets/img/create-model/female-anime/ethnicities/white.webp'
import femaleAnimeBrownAvif from '@/assets/img/create-model/female-anime/ethnicities/brown.avif'
import femaleAnimeBrownWebp from '@/assets/img/create-model/female-anime/ethnicities/brown.webp'
import femaleAnimeBlackAvif from '@/assets/img/create-model/female-anime/ethnicities/black.avif'
import femaleAnimeBlackWebp from '@/assets/img/create-model/female-anime/ethnicities/black.webp'

// Female Anime Ages
import femaleAnimeTwentiesAvif from '@/assets/img/create-model/female-anime/ages/twenties.avif'
import femaleAnimeTwentiesWebp from '@/assets/img/create-model/female-anime/ages/twenties.webp'
import femaleAnimeThirtiesAvif from '@/assets/img/create-model/female-anime/ages/thirties.avif'
import femaleAnimeThirtiesWebp from '@/assets/img/create-model/female-anime/ages/thirties.webp'
import femaleAnimeFortiesAvif from '@/assets/img/create-model/female-anime/ages/fourties.avif'
import femaleAnimeFortiesWebp from '@/assets/img/create-model/female-anime/ages/fourties.webp'
import femaleAnimeFiftiesAvif from '@/assets/img/create-model/female-anime/ages/fifties.avif'
import femaleAnimeFiftiesWebp from '@/assets/img/create-model/female-anime/ages/fifties.webp'

// Female Anime Eye Colors
import femaleAnimeBlackEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/black.avif'
import femaleAnimeBlackEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/black.webp'
import femaleAnimeBrownEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/brown.avif'
import femaleAnimeBrownEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/brown.webp'
import femaleAnimeGrayEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/gray.avif'
import femaleAnimeGrayEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/gray.webp'
import femaleAnimeBlueEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/blue.avif'
import femaleAnimeBlueEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/blue.webp'
import femaleAnimeGreenEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/green.avif'
import femaleAnimeGreenEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/green.webp'

// Female Anime Hair Styles
import femaleAnimeStraightHairAvif from '@/assets/img/create-model/female-anime/hair-styles/straight.avif'
import femaleAnimeStraightHairWebp from '@/assets/img/create-model/female-anime/hair-styles/straight.webp'
import femaleAnimeCurlyHairAvif from '@/assets/img/create-model/female-anime/hair-styles/curly.avif'
import femaleAnimeCurlyHairWebp from '@/assets/img/create-model/female-anime/hair-styles/curly.webp'
import femaleAnimePonytailHairAvif from '@/assets/img/create-model/female-anime/hair-styles/ponytail.avif'
import femaleAnimePonytailHairWebp from '@/assets/img/create-model/female-anime/hair-styles/ponytail.webp'
import femaleAnimeBraidsHairAvif from '@/assets/img/create-model/female-anime/hair-styles/braids.avif'
import femaleAnimeBraidsHairWebp from '@/assets/img/create-model/female-anime/hair-styles/braids.webp'

// Female Anime Hair Length
import femaleAnimeLongHairAvif from '@/assets/img/create-model/female-anime/hair-lengths/long.avif'
import femaleAnimeLongHairWebp from '@/assets/img/create-model/female-anime/hair-lengths/long.webp'
import femaleAnimeShortHairAvif from '@/assets/img/create-model/female-anime/hair-lengths/short.avif'
import femaleAnimeShortHairWebp from '@/assets/img/create-model/female-anime/hair-lengths/short.webp'

// Female Anime Hair Colors
import femaleAnimeBrunetteHairAvif from '@/assets/img/create-model/female-anime/hair-colors/brunette.avif'
import femaleAnimeBrunetteHairWebp from '@/assets/img/create-model/female-anime/hair-colors/brunette.webp'
import femaleAnimeBlackHairAvif from '@/assets/img/create-model/female-anime/hair-colors/black.avif'
import femaleAnimeBlackHairWebp from '@/assets/img/create-model/female-anime/hair-colors/black.webp'
import femaleAnimeBlondeHairAvif from '@/assets/img/create-model/female-anime/hair-colors/blonde.avif'
import femaleAnimeBlondeHairWebp from '@/assets/img/create-model/female-anime/hair-colors/blonde.webp'
import femaleAnimeGingerHairAvif from '@/assets/img/create-model/female-anime/hair-colors/ginger.avif'
import femaleAnimeGingerHairWebp from '@/assets/img/create-model/female-anime/hair-colors/ginger.webp'
import femaleAnimePinkHairAvif from '@/assets/img/create-model/female-anime/hair-colors/pink.avif'
import femaleAnimePinkHairWebp from '@/assets/img/create-model/female-anime/hair-colors/pink.webp'
import femaleAnimePurpleHairAvif from '@/assets/img/create-model/female-anime/hair-colors/purple.avif'
import femaleAnimePurpleHairWebp from '@/assets/img/create-model/female-anime/hair-colors/purple.webp'

// Female Anime Body Type
import femaleAnimeSkinnyAvif from '@/assets/img/create-model/female-anime/body-types/skinny.avif'
import femaleAnimeSkinnyWebp from '@/assets/img/create-model/female-anime/body-types/skinny.webp'
import femaleAnimeSlimAvif from '@/assets/img/create-model/female-anime/body-types/slim.avif'
import femaleAnimeSlimWebp from '@/assets/img/create-model/female-anime/body-types/slim.webp'
import femaleAnimeAthleticAvif from '@/assets/img/create-model/female-anime/body-types/athletic.avif'
import femaleAnimeAthleticWebp from '@/assets/img/create-model/female-anime/body-types/athletic.webp'
import femaleAnimeThickAvif from '@/assets/img/create-model/female-anime/body-types/thick.avif'
import femaleAnimeThickWebp from '@/assets/img/create-model/female-anime/body-types/thick.webp'

// Breast Sizes
import femaleAnimeSmallBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/small.avif'
import femaleAnimeSmallBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/small.webp'
import femaleAnimeAverageBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/average.avif'
import femaleAnimeAverageBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/average.webp'
import femaleAnimeLargeBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/large.avif'
import femaleAnimeLargeBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/large.webp'
import femaleAnimeHugeBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/huge.avif'
import femaleAnimeHugeBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/huge.webp'

// Female Anime Butt Sizes
import femaleAnimeSmallButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/small.avif'
import femaleAnimeSmallButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/small.webp'
import femaleAnimeAverageButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/average.avif'
import femaleAnimeAverageButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/average.webp'
import femaleAnimeLargeButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/large.avif'
import femaleAnimeLargeButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/large.webp'
import femaleAnimeHugeButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/huge.avif'
import femaleAnimeHugeButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/huge.webp'

// Male Anime Ethnicities
import maleAnimeWhiteAvif from '@/assets/img/create-model/male-anime/ethnicities/white.avif'
import maleAnimeWhiteWebp from '@/assets/img/create-model/male-anime/ethnicities/white.webp'
import maleAnimeBrownAvif from '@/assets/img/create-model/male-anime/ethnicities/brown.avif'
import maleAnimeBrownWebp from '@/assets/img/create-model/male-anime/ethnicities/brown.webp'
import maleAnimeBlackAvif from '@/assets/img/create-model/male-anime/ethnicities/black.avif'
import maleAnimeBlackWebp from '@/assets/img/create-model/male-anime/ethnicities/black.webp'

// Male Anime Ages
import maleAnimeTwentiesAvif from '@/assets/img/create-model/male-anime/ages/twenties.avif'
import maleAnimeTwentiesWebp from '@/assets/img/create-model/male-anime/ages/twenties.webp'
import maleAnimeThirtiesAvif from '@/assets/img/create-model/male-anime/ages/thirties.avif'
import maleAnimeThirtiesWebp from '@/assets/img/create-model/male-anime/ages/thirties.webp'
import maleAnimeFortiesAvif from '@/assets/img/create-model/male-anime/ages/fourties.avif'
import maleAnimeFortiesWebp from '@/assets/img/create-model/male-anime/ages/fourties.webp'
import maleAnimeFiftiesAvif from '@/assets/img/create-model/male-anime/ages/fifties.avif'
import maleAnimeFiftiesWebp from '@/assets/img/create-model/male-anime/ages/fifties.webp'

// Male Anime Eye Colors
import maleAnimeBlackEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/black.avif'
import maleAnimeBlackEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/black.webp'
import maleAnimeBrownEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/brown.avif'
import maleAnimeBrownEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/brown.webp'
import maleAnimeGrayEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/gray.avif'
import maleAnimeGrayEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/gray.webp'
import maleAnimeBlueEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/blue.avif'
import maleAnimeBlueEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/blue.webp'
import maleAnimeGreenEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/green.avif'
import maleAnimeGreenEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/green.webp'

// Male Anime Hair Styles
import maleAnimeStraightHairAvif from '@/assets/img/create-model/male-anime/hair-styles/straight.avif'
import maleAnimeStraightHairWebp from '@/assets/img/create-model/male-anime/hair-styles/straight.webp'
import maleAnimeCurlyHairAvif from '@/assets/img/create-model/male-anime/hair-styles/curly.avif'
import maleAnimeCurlyHairWebp from '@/assets/img/create-model/male-anime/hair-styles/curly.webp'
import maleAnimeDreadlocksHairAvif from '@/assets/img/create-model/male-anime/hair-styles/dreadlocks.avif'
import maleAnimeDreadlocksHairWebp from '@/assets/img/create-model/male-anime/hair-styles/dreadlocks.webp'
import maleAnimeBuzzCutHairAvif from '@/assets/img/create-model/male-anime/hair-styles/buzzCut.avif'
import maleAnimeBuzzCutHairWebp from '@/assets/img/create-model/male-anime/hair-styles/buzzCut.webp'

// Male Anime Hair Colors
import maleAnimeBrownHairAvif from '@/assets/img/create-model/male-anime/hair-colors/brown.avif'
import maleAnimeBrownHairWebp from '@/assets/img/create-model/male-anime/hair-colors/brown.webp'
import maleAnimeBlackHairAvif from '@/assets/img/create-model/male-anime/hair-colors/black.avif'
import maleAnimeBlackHairWebp from '@/assets/img/create-model/male-anime/hair-colors/black.webp'
import maleAnimeBlondeHairAvif from '@/assets/img/create-model/male-anime/hair-colors/blonde.avif'
import maleAnimeBlondeHairWebp from '@/assets/img/create-model/male-anime/hair-colors/blonde.webp'
import maleAnimeGingerHairAvif from '@/assets/img/create-model/male-anime/hair-colors/ginger.avif'
import maleAnimeGingerHairWebp from '@/assets/img/create-model/male-anime/hair-colors/ginger.webp'

// Male Anime Body Types
import maleAnimeMuscularAvif from '@/assets/img/create-model/male-anime/body-types/muscular.avif'
import maleAnimeMuscularWebp from '@/assets/img/create-model/male-anime/body-types/muscular.webp'
import maleAnimeSlimAvif from '@/assets/img/create-model/male-anime/body-types/slim.avif'
import maleAnimeSlimWebp from '@/assets/img/create-model/male-anime/body-types/slim.webp'

export const stepsGender = [
  {
    displayName: 'Gender',
    modifies: 'gender',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAvif,
          webp: femaleWebp,
        },
        displayName: 'Girlfriend',
        value: 'female',
      },
      {
        image: {
          avif: maleAvif,
          webp: maleWebp,
        },
        displayName: 'Boyfriend',
        value: 'male',
      },
    ],
  },
]

export const stepsFemaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleRealisticAvif,
          webp: femaleRealisticWebp,
        },
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: {
          avif: femaleAnimeAvif,
          webp: femaleAnimeWebp,
        },
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleCaucasianAvif,
          webp: femaleCaucasianWebp,
        },
        displayName: 'Caucasian',
        value: 'caucasian',
      },
      {
        image: {
          avif: femaleAsianAvif,
          webp: femaleAsianWebp,
        },
        displayName: 'Asian',
        value: 'asian',
      },
      {
        image: {
          avif: femaleArabicAvif,
          webp: femaleArabicWebp,
        },
        displayName: 'Arabic',
        value: 'arabic',
      },
      {
        image: {
          avif: femaleIndianAvif,
          webp: femaleIndianWebp,
        },
        displayName: 'Indian',
        value: 'indian',
      },
      {
        image: {
          avif: femaleLatinaAvif,
          webp: femaleLatinaWebp,
        },
        displayName: 'Latina',
        value: 'latina',
      },
      {
        image: {
          avif: femaleAfricanAvif,
          webp: femaleAfricanWebp,
        },
        displayName: 'African',
        value: 'african',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleTwentiesAvif,
          webp: femaleTwentiesWebp,
        },
        displayName: 'Barely Legal (18)',
        value: 18,
      },
      {
        image: {
          avif: femaleThirtiesAvif,
          webp: femaleThirtiesWebp,
        },
        displayName: 'Twenties',
        value: 25,
      },
      {
        image: {
          avif: femaleFortiesAvif,
          webp: femaleFortiesWebp,
        },
        displayName: 'Thirties',
        value: 35,
      },
      {
        image: {
          avif: femaleFiftiesAvif,
          webp: femaleFiftiesWebp,
        },
        displayName: 'Fourties',
        value: 45,
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleBlackEyeAvif,
          webp: femaleBlackEyeWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: femaleBrownEyeAvif,
          webp: femaleBrownEyeWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: femaleGrayEyeAvif,
          webp: femaleGrayEyeWebp,
        },
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: {
          avif: femaleBlueEyeAvif,
          webp: femaleBlueEyeWebp,
        },
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: {
          avif: femaleGreenEyeAvif,
          webp: femaleGreenEyeWebp,
        },
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleStraightHairAvif,
          webp: femaleStraightHairWebp,
        },
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: {
          avif: femaleCurlyHairAvif,
          webp: femaleCurlyHairWebp,
        },
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: {
          avif: femalePonytailAvif,
          webp: femalePonytailWebp,
        },
        displayName: 'Ponytail',
        value: 'ponytail',
      },
      {
        image: {
          avif: femaleBraidsAvif,
          webp: femaleBraidsWebp,
        },
        displayName: 'Braids',
        value: 'braids',
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleLongHairAvif,
          webp: femaleLongHairWebp,
        },
        displayName: 'Long',
        value: 'long',
      },
      {
        image: {
          avif: femaleShortHairAvif,
          webp: femaleShortHairWebp,
        },
        displayName: 'Short',
        value: 'short',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleBrunetteHairAvif,
          webp: femaleBrunetteHairWebp,
        },
        displayName: 'Brunette',
        value: 'brunette',
      },
      {
        image: {
          avif: femaleBlackHairAvif,
          webp: femaleBlackHairWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: femaleBlondeHairAvif,
          webp: femaleBlondeHairWebp,
        },
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: {
          avif: femaleGingerHairAvif,
          webp: femaleGingerHairWebp,
        },
        displayName: 'Ginger',
        value: 'ginger',
      },
      {
        image: {
          avif: femalePinkHairAvif,
          webp: femalePinkHairWebp,
        },
        displayName: 'Pink',
        value: 'pink',
      },
      {
        image: {
          avif: femalePurpleHairAvif,
          webp: femalePurpleHairWebp,
        },
        displayName: 'Purple',
        value: 'purple',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleSkinnyAvif,
          webp: femaleSkinnyWebp,
        },
        displayName: 'Skinny',
        value: 'skinny',
      },
      {
        image: {
          avif: femaleSlimAvif,
          webp: femaleSlimWebp,
        },
        displayName: 'Slim',
        value: 'slim',
      },
      {
        image: {
          avif: femaleAthleticAvif,
          webp: femaleAthleticWebp,
        },
        displayName: 'Athletic',
        value: 'athletic',
      },
      {
        image: {
          avif: femaleThickAvif,
          webp: femaleThickWebp,
        },
        displayName: 'Thick',
        value: 'thick',
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleSmallBreastAvif,
          webp: femaleSmallBreastWebp,
        },
        displayName: 'Small',
        value: 'small',
      },
      {
        image: {
          avif: femaleAverageBreastAvif,
          webp: femaleAverageBreastWebp,
        },
        displayName: 'Average',
        value: 'average',
      },
      {
        image: {
          avif: femaleLargeBreastAvif,
          webp: femaleLargeBreastWebp,
        },
        displayName: 'Large',
        value: 'large',
      },
      {
        image: {
          avif: femaleHugeBreastAvif,
          webp: femaleHugeBreastWebp,
        },
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleSmallButtAvif,
          webp: femaleSmallButtWebp,
        },
        displayName: 'Small',
        value: 'small',
      },
      {
        image: {
          avif: femaleAverageButtAvif,
          webp: femaleAverageButtWebp,
        },
        displayName: 'Average',
        value: 'average',
      },
      {
        image: {
          avif: femaleLargeButtAvif,
          webp: femaleLargeButtWebp,
        },
        displayName: 'Large',
        value: 'large',
      },
      {
        image: {
          avif: femaleHugeButtAvif,
          webp: femaleHugeButtWebp,
        },
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: angel,
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: flameAlt,
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: snowflake,
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        value: 'new-us-young-1',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-1',
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-2',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-2',
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-3',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-3',
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-4',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-4',
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-5',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-5.mp3',
          true,
        ),
        displayName: 'American 5',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-5',
        preview: cdnFile('model-creator/female/voices/new-us-old-5.mp3', true),
        displayName: 'American 5',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-6',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-6.mp3',
          true,
        ),
        displayName: 'American 6',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-6',
        preview: cdnFile('model-creator/female/voices/new-us-old-6.mp3', true),
        displayName: 'American 6',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-7',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-7.mp3',
          true,
        ),
        displayName: 'American 7',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-7',
        preview: cdnFile('model-creator/female/voices/new-us-old-7.mp3', true),
        displayName: 'American 7',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-8',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-8.mp3',
          true,
        ),
        displayName: 'American 8',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-8',
        preview: cdnFile('model-creator/female/voices/new-us-old-8.mp3', true),
        displayName: 'American 8',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        value: 'new-gb-young',
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        value: 'new-gb-old',
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        value: 'new-in-young',
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        value: 'new-in-old',
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-young',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-old',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        value: 'new-au-young',
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        value: 'new-au-old',
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagFr,
        value: 'new-fr',
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
        displayName: 'French',
        ethnicities: ['caucasian', 'african'],
        startAge: 0,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsFemaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleRealisticAvif,
          webp: femaleRealisticAvif,
        },
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: {
          avif: femaleAnimeAvif,
          webp: femaleAnimeWebp,
        },
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeWhiteAvif,
          webp: femaleAnimeWhiteWebp,
        },
        displayName: 'White',
        value: 'white',
      },
      {
        image: {
          avif: femaleAnimeBrownAvif,
          webp: femaleAnimeBrownWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: femaleAnimeBlackAvif,
          webp: femaleAnimeBlackWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeTwentiesAvif,
          webp: femaleAnimeTwentiesWebp,
        },
        displayName: 'Barely Legal (18)',
        value: 18,
      },
      {
        image: {
          avif: femaleAnimeThirtiesAvif,
          webp: femaleAnimeThirtiesWebp,
        },
        displayName: 'Twenties',
        value: 25,
      },
      {
        image: {
          avif: femaleAnimeFortiesAvif,
          webp: femaleAnimeFortiesWebp,
        },
        displayName: 'Thirties',
        value: 35,
      },
      {
        image: {
          avif: femaleAnimeFiftiesAvif,
          webp: femaleAnimeFiftiesWebp,
        },
        displayName: 'Fourties',
        value: 45,
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeBlackEyeAvif,
          webp: femaleAnimeBlackEyeWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: femaleAnimeBrownEyeAvif,
          webp: femaleAnimeBrownEyeWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: femaleAnimeGrayEyeAvif,
          webp: femaleAnimeGrayEyeWebp,
        },
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: {
          avif: femaleAnimeBlueEyeAvif,
          webp: femaleAnimeBlueEyeWebp,
        },
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: {
          avif: femaleAnimeGreenEyeAvif,
          webp: femaleAnimeGreenEyeWebp,
        },
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeStraightHairAvif,
          webp: femaleAnimeStraightHairWebp,
        },
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: {
          avif: femaleAnimeCurlyHairAvif,
          webp: femaleAnimeCurlyHairWebp,
        },
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: {
          avif: femaleAnimePonytailHairAvif,
          webp: femaleAnimePonytailHairWebp,
        },
        displayName: 'Ponytail',
        value: 'ponytail',
      },
      {
        image: {
          avif: femaleAnimeBraidsHairAvif,
          webp: femaleAnimeBraidsHairWebp,
        },
        displayName: 'Braids',
        value: 'braids',
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeLongHairAvif,
          webp: femaleAnimeLongHairWebp,
        },
        displayName: 'Long',
        value: 'long',
      },
      {
        image: {
          avif: femaleAnimeShortHairAvif,
          webp: femaleAnimeShortHairWebp,
        },
        displayName: 'Short',
        value: 'short',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeBrunetteHairAvif,
          webp: femaleAnimeBrunetteHairWebp,
        },
        displayName: 'Brunette',
        value: 'brunette',
      },
      {
        image: {
          avif: femaleAnimeBlackHairAvif,
          webp: femaleAnimeBlackHairWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: femaleAnimeBlondeHairAvif,
          webp: femaleAnimeBlondeHairWebp,
        },
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: {
          avif: femaleAnimeGingerHairAvif,
          webp: femaleAnimeGingerHairWebp,
        },
        displayName: 'Ginger',
        value: 'ginger',
      },
      {
        image: {
          avif: femaleAnimePinkHairAvif,
          webp: femaleAnimePinkHairWebp,
        },
        displayName: 'Pink',
        value: 'pink',
      },
      {
        image: {
          avif: femaleAnimePurpleHairAvif,
          webp: femaleAnimePurpleHairWebp,
        },
        displayName: 'Purple',
        value: 'purple',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeSkinnyAvif,
          webp: femaleAnimeSkinnyWebp,
        },
        displayName: 'Skinny',
        value: 'skinny',
      },
      {
        image: {
          avif: femaleAnimeSlimAvif,
          webp: femaleAnimeSlimWebp,
        },
        displayName: 'Slim',
        value: 'slim',
      },
      {
        image: {
          avif: femaleAnimeAthleticAvif,
          webp: femaleAnimeAthleticWebp,
        },
        displayName: 'Athletic',
        value: 'athletic',
      },
      {
        image: {
          avif: femaleAnimeThickAvif,
          webp: femaleAnimeThickWebp,
        },
        displayName: 'Thick',
        value: 'thick',
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeSmallBreastAvif,
          webp: femaleAnimeSmallBreastWebp,
        },
        displayName: 'Small',
        value: 'small',
      },
      {
        image: {
          avif: femaleAnimeAverageBreastAvif,
          webp: femaleAnimeAverageBreastWebp,
        },
        displayName: 'Average',
        value: 'average',
      },
      {
        image: {
          avif: femaleAnimeLargeBreastAvif,
          webp: femaleAnimeLargeBreastWebp,
        },
        displayName: 'Large',
        value: 'large',
      },
      {
        image: {
          avif: femaleAnimeHugeBreastAvif,
          webp: femaleAnimeHugeBreastWebp,
        },
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: femaleAnimeSmallButtAvif,
          webp: femaleAnimeSmallButtWebp,
        },
        displayName: 'Small',
        value: 'small',
      },
      {
        image: {
          avif: femaleAnimeAverageButtAvif,
          webp: femaleAnimeAverageButtWebp,
        },
        displayName: 'Average',
        value: 'average',
      },
      {
        image: {
          avif: femaleAnimeLargeButtAvif,
          webp: femaleAnimeLargeButtWebp,
        },
        displayName: 'Large',
        value: 'large',
      },
      {
        image: {
          avif: femaleAnimeHugeButtAvif,
          webp: femaleAnimeHugeButtWebp,
        },
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: angel,
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: flameAlt,
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: snowflake,
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        value: 'new-us-young-1',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-1',
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-2',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-2',
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-3',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-3',
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-4',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-4',
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-5',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-5.mp3',
          true,
        ),
        displayName: 'American 5',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-5',
        preview: cdnFile('model-creator/female/voices/new-us-old-5.mp3', true),
        displayName: 'American 5',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-6',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-6.mp3',
          true,
        ),
        displayName: 'American 6',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-6',
        preview: cdnFile('model-creator/female/voices/new-us-old-6.mp3', true),
        displayName: 'American 6',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-7',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-7.mp3',
          true,
        ),
        displayName: 'American 7',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-7',
        preview: cdnFile('model-creator/female/voices/new-us-old-7.mp3', true),
        displayName: 'American 7',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-8',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-8.mp3',
          true,
        ),
        displayName: 'American 8',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-8',
        preview: cdnFile('model-creator/female/voices/new-us-old-8.mp3', true),
        displayName: 'American 8',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        value: 'new-gb-young',
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        value: 'new-gb-old',
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        value: 'new-in-young',
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        value: 'new-in-old',
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-young',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-old',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        value: 'new-au-young',
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        value: 'new-au-old',
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagFr,
        value: 'new-fr',
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
        displayName: 'French',
        ethnicities: ['white', 'black'],
        startAge: 0,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsMaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleRealisticAvif,
          webp: maleRealisticWebp,
        },
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: {
          avif: maleAnimeAvif,
          webp: maleAnimeWebp,
        },
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleCaucasianAvif,
          webp: maleCaucasianWebp,
        },
        displayName: 'Caucasian',
        value: 'caucasian',
      },
      {
        image: {
          avif: maleAsianAvif,
          webp: maleAsianWebp,
        },
        displayName: 'Asian',
        value: 'asian',
      },
      {
        image: {
          avif: maleArabicAvif,
          webp: maleArabicWebp,
        },
        displayName: 'Arabic',
        value: 'arabic',
      },
      {
        image: {
          avif: maleIndianAvif,
          webp: maleIndianWebp,
        },
        displayName: 'Indian',
        value: 'indian',
      },
      {
        image: {
          avif: maleLatinoAvif,
          webp: maleLatinoWebp,
        },
        displayName: 'Latino',
        value: 'latino',
      },
      {
        image: {
          avif: maleAfricanAvif,
          webp: maleAfricanWebp,
        },
        displayName: 'African',
        value: 'african',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleTwentiesAvif,
          webp: maleTwentiesWebp,
        },
        displayName: 'Barely Legal (18)',
        value: 18,
      },
      {
        image: {
          avif: maleThirtiesAvif,
          webp: maleThirtiesWebp,
        },
        displayName: 'Twenties',
        value: 25,
      },
      {
        image: {
          avif: maleFortiesAvif,
          webp: maleFortiesWebp,
        },
        displayName: 'Thirties',
        value: 35,
      },
      {
        image: {
          avif: maleFiftiesAvif,
          webp: maleFiftiesWebp,
        },
        displayName: 'Fourties',
        value: 45,
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleBlackEyeAvif,
          webp: maleBlackEyeWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: maleBrownEyeAvif,
          webp: maleBrownEyeWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: maleGrayEyeAvif,
          webp: maleGrayEyeWebp,
        },
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: {
          avif: maleBlueEyeAvif,
          webp: maleBlueEyeWebp,
        },
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: {
          avif: maleGreenEyeAvif,
          webp: maleGreenEyeWebp,
        },
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleStraightHairAvif,
          webp: maleStraightHairWebp,
        },
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: {
          avif: maleCurlyHairAvif,
          webp: maleCurlyHairWebp,
        },
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: {
          avif: maleDreadlocksAvif,
          webp: maleDreadlocksWebp,
        },
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
      },
      {
        image: {
          avif: maleBuzzCutAvif,
          webp: maleBuzzCutWebp,
        },
        displayName: 'Buzz Cut',
        value: 'buzz cut',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleBrownHairAvif,
          webp: maleBrownHairWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: maleBlackHairAvif,
          webp: maleBlackHairWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: maleBlondeHairAvif,
          webp: maleBlondeHairWebp,
        },
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: {
          avif: maleGingerHairAvif,
          webp: maleGingerHairWebp,
        },
        displayName: 'Ginger',
        value: 'ginger',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleMuscularAvif,
          webp: maleMuscularWebp,
        },
        displayName: 'Muscular',
        value: 'muscular',
      },
      {
        image: {
          avif: maleSlimAvif,
          webp: maleSlimWebp,
        },
        displayName: 'Slim',
        value: 'slim',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: caring,
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: protective,
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
      },
      {
        icon: badBoy,
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        displayName: 'American 1',
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
        value: 'male-us',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 18,
        endAge: 80,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-young.mp3', true),
        value: 'male-us-young',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
        value: 'male-us-old',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
        value: 'male-gb-young',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
        value: 'male-gb-old',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
        value: 'male-au-young',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
        value: 'male-au-old',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
        value: 'male-id-young',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
        value: 'male-id-old',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
        value: 'male-africa-young',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
        value: 'male-africa-old',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsMaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleRealisticAvif,
          webp: maleRealisticWebp,
        },
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: {
          avif: maleAnimeAvif,
          webp: maleAnimeWebp,
        },
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeWhiteAvif,
          webp: maleAnimeWhiteWebp,
        },
        displayName: 'White',
        value: 'white',
      },
      {
        image: {
          avif: maleAnimeBrownAvif,
          webp: maleAnimeBrownWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: maleAnimeBlackAvif,
          webp: maleAnimeBlackWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeTwentiesAvif,
          webp: maleAnimeTwentiesWebp,
        },
        displayName: 'Barely Legal (18)',
        value: 18,
      },
      {
        image: {
          avif: maleAnimeThirtiesAvif,
          webp: maleAnimeThirtiesWebp,
        },
        displayName: 'Twenties',
        value: 25,
      },
      {
        image: {
          avif: maleAnimeFortiesAvif,
          webp: maleAnimeFortiesWebp,
        },
        displayName: 'Thirties',
        value: 35,
      },
      {
        image: {
          avif: maleAnimeFiftiesAvif,
          webp: maleAnimeFiftiesWebp,
        },
        displayName: 'Fourties',
        value: 45,
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeBlackEyeAvif,
          webp: maleAnimeBlackEyeWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: maleAnimeBrownEyeAvif,
          webp: maleAnimeBrownEyeWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: maleAnimeGrayEyeAvif,
          webp: maleAnimeGrayEyeWebp,
        },
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: {
          avif: maleAnimeBlueEyeAvif,
          webp: maleAnimeBlueEyeWebp,
        },
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: {
          avif: maleAnimeGreenEyeAvif,
          webp: maleAnimeGreenEyeWebp,
        },
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeStraightHairAvif,
          webp: maleAnimeStraightHairWebp,
        },
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: {
          avif: maleAnimeCurlyHairAvif,
          webp: maleAnimeCurlyHairWebp,
        },
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: {
          avif: maleAnimeDreadlocksHairAvif,
          webp: maleAnimeDreadlocksHairWebp,
        },
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
      },
      {
        image: {
          avif: maleAnimeBuzzCutHairAvif,
          webp: maleAnimeBuzzCutHairWebp,
        },
        displayName: 'Buzz Cut',
        value: 'buzz cut',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeBrownHairAvif,
          webp: maleAnimeBrownHairWebp,
        },
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: {
          avif: maleAnimeBlackHairAvif,
          webp: maleAnimeBlackHairWebp,
        },
        displayName: 'Black',
        value: 'black',
      },
      {
        image: {
          avif: maleAnimeBlondeHairAvif,
          webp: maleAnimeBlondeHairWebp,
        },
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: {
          avif: maleAnimeGingerHairAvif,
          webp: maleAnimeGingerHairWebp,
        },
        displayName: 'Ginger',
        value: 'ginger',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: {
          avif: maleAnimeMuscularAvif,
          webp: maleAnimeMuscularWebp,
        },
        displayName: 'Muscular',
        value: 'muscular',
      },
      {
        image: {
          avif: maleAnimeSlimAvif,
          webp: maleAnimeSlimWebp,
        },
        displayName: 'Slim',
        value: 'slim',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: caring,
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: protective,
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
      },
      {
        icon: badBoy,
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        displayName: 'American 1',
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
        value: 'male-us',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 18,
        endAge: 80,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-young.mp3', true),
        value: 'male-us-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
        value: 'male-us-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
        value: 'male-gb-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
        value: 'male-gb-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
        value: 'male-au-young',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
        value: 'male-au-old',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
        value: 'male-id-young',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
        value: 'male-id-old',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
        value: 'male-africa-young',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
        value: 'male-africa-old',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]
