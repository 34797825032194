<script setup>
import { toRefs, ref, computed, inject } from 'vue'
import logo from '@/assets/logo.svg'
import { useHead } from '@unhead/vue'
import plusCircle from '@/assets/icons/plus-circle.svg'
import minus from '@/assets/icons/minus.svg'
import { useMixpanel } from '@/composables/mixpanel'
import premadeModels from 'common/lib/premade.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ModelCard from '@/components/ModelCard.vue'
import FooterComponent from '@/components/Footer.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import magicWand from '@/assets/icons/magic-wand.svg'
import usersPlus from '@/assets/icons/users-plus.svg'
import face2 from '@/assets/icons/face-2.svg'
import maleFaceLine from '@/assets/icons/male-face-line.svg'
import messageChatSquare from '@/assets/icons/navigation/message-chat-square.svg'
import { useToast } from 'vue-toast-notification'
import { useWindowSize } from '@vueuse/core'
import Confirm18 from '@/components/Confirm18.vue'

const props = defineProps({
  keyphrase: String,
  warningText: String,
  point1: {
    type: String,
    default: '',
  },
  point2: {
    type: String,
    default: '',
  },
  point3: {
    type: String,
    default: '',
  },
  stepTitle1: {
    type: String,
    default: '',
  },
  stepTitle2: {
    type: String,
    default: '',
  },
  stepTitle3: {
    type: String,
    default: '',
  },
  confirm18: {
    type: Boolean,
    default: false,
  },
  version18: {
    type: Boolean,
    default: false,
  },
  headline: String,
  ctaPhrase: String,
  faqs: Array,
  gender: String,
})

const windowSize = useWindowSize()

const isMobileViewModelCard = computed(() => windowSize.width.value < 768)

const { keyphrase, headline, ctaPhrase, faqs, gender } = toRefs(props)

const brandName = import.meta.env.VITE_BRAND_NAME

useHead({
  title: `${brandName} | ${keyphrase.value}`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

const $router = useRouter()
const $store = useStore()
const $axios = inject('axios')
const $toast = useToast()

const premadeModel = computed(() => $store.state.premadeModel)

const mixpanel = useMixpanel()
mixpanel.track('lp_search_search_visit', {})

const faqOpen = ref({})

function toggleFaq(faq) {
  if (!faqOpen.value[faq.question])
    mixpanel.track('lp_search_faq_click', { selection: faq.question })

  faqOpen.value[faq.question] = !faqOpen.value[faq.question]
}

const style = ref('realistic')

const shownPremadeModels = computed(() =>
  premadeModels
    .filter(
      (el) =>
        el.model.gender === gender.value && el.model.style === style.value,
    )
    .slice(0, 8),
)

function setStyle(newStyle) {
  style.value = newStyle
  mixpanel.track('lp_search_realistic_anime_click', {
    selection: newStyle,
  })
}

function mixpanelModelClick(model, towards) {
  mixpanel.track('lp_search_model_click', {
    model_id: model.premadeId,
    model_name: model.name,
    model_gender: model.gender,
    model_position:
      shownPremadeModels.value.findIndex(
        (el) => el.model.premadeId === model.premadeId,
      ) + 1,
    towards,
    fixedOrderBug: true,
  })
  mixpanel.track('lp_move_forward', { cta: 'model_click' })
}

async function createModel(model) {
  mixpanelModelClick(model, 'chat')

  // Here we can assume that the user has not registered yet
  // because if they had registered, they would have been redirected
  // to the home page instead
  if (premadeModel.value?.premadeId === model.premadeId) {
    $store.commit('SET_SELECTED_MODEL', model.id)
    await $router.push('/chat')
  } else {
    await $store.dispatch('setPremadeModel', model)
    await $store.commit('SET_SELECTED_MODEL', model.premadeId)
    await $router.push('/chat')
    await $axios
      .post('/model/create/premade', {
        model,
      })
      .then(({ data: { model: createdModel, changedLuna } }) => {
        $store.dispatch('setPremadeModel', createdModel)
        $store.commit('SET_SELECTED_MODEL', createdModel.id)
      })
      .catch((e) => {
        const message = e?.response?.data?.message
        return $toast.error(message || 'An error occurred')
      })
  }
}

const redirectTo = computed(() =>
  gender.value === 'female' ? '/' : '/ai-boyfriend',
)

function redirect() {
  mixpanel.track('lp_move_forward', { cta: 'button_cta_click' })
  $router.push(redirectTo.value)
}
</script>

<template>
  <div
    class="max-w-7xl mx-auto flex flex-col gap-14 sm:gap-20 pt-10 lg:pt-10 px-4 sm:px-8"
  >
    <Confirm18 v-if="confirm18"></Confirm18>
    <img :src="logo" alt="Logo" class="w-36 sm:w-40 lg:w-52 mx-auto" />
    <div class="flex flex-col items-center gap-8">
      <h1
        class="text-3xl sm:text-4xl md:text-5xl font-bold text-center leading-[1.3] sm:leading-[1.3] md:leading-[1.3] tracking-tighter"
        v-html="headline"
      ></h1>
      <ul class="flex flex-col gap-3 md:text-lg">
        <li class="flex items-center gap-2">
          <CheckCircleIcon class="h-5 w-5"></CheckCircleIcon>
          {{ point1 || 'Both Hyperrealistic & Anime Characters' }}
        </li>
        <li class="flex items-center gap-2">
          <CheckCircleIcon class="h-5 w-5"></CheckCircleIcon>
          {{ point2 || 'Customize Looks & Personality Traits' }}
        </li>
        <li class="flex items-center gap-2">
          <CheckCircleIcon class="h-5 w-5"></CheckCircleIcon>
          {{
            point3 ||
            `Chat, Speak, or Exchange Pics with ${gender === 'female' ? 'Her' : 'Him'}`
          }}
        </li>
      </ul>
      <ButtonComponent
        :to="redirectTo"
        @click.stop="redirect"
        class="flex justify-center items-center h-14 md:h-16 w-72 font-bold text-lg gap-2"
        ><img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
        {{ ctaPhrase }}</ButtonComponent
      >
      <p class="text-center max-w-lg leading-relaxed">
        <span class="font-semibold">Warning:</span> {{ warningText }}
      </p>
    </div>

    <div>
      <div
        class="lg:max-w-[480px] cursor-pointer sm:max-w-unset mx-auto p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mb-3 lg:mb-[20px] lg:mt-0 flex"
      >
        <div
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              style == 'realistic',
          }"
          @click="setStyle('realistic')"
        >
          <h3>Realistic</h3>
        </div>
        <div
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              style == 'anime',
          }"
          @click="setStyle('anime')"
        >
          <h3>Anime</h3>
        </div>
      </div>
      <div
        class="explore-models-container grid grid-cols-2 sm:flex gap-2 lg:gap-6 flex-wrap"
      >
        <ModelCard
          v-for="(model, i) in shownPremadeModels"
          :key="model.model.premadeId"
          :position="i"
          :model-info="model"
          class="w-full m-auto sm:m-0 sm:max-w-full sm:w-[calc((100%-1.5rem)/2)] md:w-[calc((100%-3rem)/3)] xl:w-[calc((100%-4.5rem)/4)] relative"
          @model-clicked="createModel(model.model)"
          @model-details-clicked="mixpanelModelClick(model.model, 'details')"
          :isMobileView="isMobileViewModelCard"
          :version18="version18"
        ></ModelCard>
      </div>
      <p class="text-center mt-8 text-lg">...and many many more</p>
    </div>

    <ButtonComponent
      class="flex justify-center items-center h-14 md:h-16 w-72 font-bold text-lg gap-2 mx-auto"
      :to="redirectTo"
      @click.stop="redirect"
      ><img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
      {{ ctaPhrase }}</ButtonComponent
    >

    <div class="flex flex-col items-center text-center gap-10 sm:gap-12">
      <h2 class="text-2xl lg:text-3xl font-bold tracking-tighter">
        How does {{ brandName }} work?
      </h2>
      <div class="flex flex-col lg:flex-row gap-16">
        <div class="flex flex-col items-center gap-4">
          <img class="h-8" :src="usersPlus" alt="Signup icon" />
          <h3 class="font-bold text-lg sm:text-xl">
            {{ stepTitle1 || 'Join Now' }}
          </h3>
          <p class="max-w-xs leading-relaxed">
            Sign up for an account on our platform in just 14 seconds.
          </p>
        </div>
        <div class="flex flex-col items-center gap-4">
          <img
            class="h-8"
            :src="gender === 'female' ? face2 : maleFaceLine"
            alt="Girl icon"
          />
          <h3 class="font-bold text-lg sm:text-xl">
            {{
              stepTitle2 ||
              `Create ${gender === 'female' ? 'Girlfriend' : 'Boyfriend'}`
            }}
          </h3>
          <p class="max-w-xs leading-relaxed">
            Select from our existing characters or create your own unique one.
          </p>
        </div>
        <div class="flex flex-col items-center gap-4">
          <img class="h-8" :src="messageChatSquare" alt="Chat icon" />
          <h3 class="font-bold text-lg sm:text-xl">
            {{ stepTitle1 || 'Start Chatting' }}
          </h3>
          <p class="max-w-xs leading-relaxed">
            Start a conversation or ask for pictures. The AI is ready to
            respond.
          </p>
        </div>
      </div>
    </div>

    <ButtonComponent
      class="flex justify-center items-center h-14 md:h-16 w-72 font-bold text-lg gap-2 mx-auto"
      :to="redirectTo"
      @click.stop="redirect"
      ><img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
      {{ ctaPhrase }}</ButtonComponent
    >

    <div class="flex justify-center gap-10 flex-col-reverse lg:flex-row">
      <div class="flex flex-col gap-8">
        <h2 class="text-2xl lg:text-3xl font-bold tracking-tighter">
          Why {{ brandName }}?
        </h2>

        <div class="flex flex-col gap-8">
          <div class="flex flex-col gap-1">
            <h3 class="text-purple-500 font-bold text-lg">Diversity</h3>
            <p>
              Discover {{ gender === 'female' ? 'girls' : 'men' }} of all
              ethnicities and personalities. Sure to find someone who matches
              your desires and needs.
            </p>
          </div>

          <div class="flex flex-col gap-1">
            <h3 class="text-purple-500 font-bold text-lg">Realness</h3>
            <p>
              Our {{ gender === 'female' ? 'girls' : 'men' }} look just like
              those you encounter in everyday life. Don't believe us? Check it
              out for yourself.
            </p>
          </div>

          <div class="flex flex-col gap-1">
            <h3 class="text-purple-500 font-bold text-lg">Inclusivity</h3>
            <p>
              Our AI models cater even to your deepest cravings. No request goes
              unanswered.
            </p>
          </div>

          <div class="flex flex-col gap-1">
            <h3 class="text-purple-500 font-bold text-lg">Privacy</h3>
            <p>
              Enjoy being yourself in a 100% judgment-free, encrypted
              environment.
            </p>
          </div>
        </div>
      </div>
      <div class="mx-auto">
        <picture>
          <source
            v-if="gender === 'female' && !version18"
            srcset="@/assets/lp-girl.avif"
            type="image/avif"
          />

          <source
            v-if="gender === 'female' && !version18"
            srcset="@/assets/lp-girl.webp"
            type="image/webp"
          />

          <source
            v-if="gender === 'female' && version18"
            srcset="@/assets/lpgirl18.avif"
            type="image/avif"
          />

          <source
            v-if="gender === 'female' && version18"
            srcset="@/assets/lpgirl18.webp"
            type="image/webp"
          />

          <source
            v-if="gender === 'male'"
            srcset="@/assets/lp-man.avif"
            type="image/avif"
          />

          <source
            v-if="gender === 'male'"
            srcset="@/assets/lp-man.webp"
            type="image/webp"
          />

          <img
            :src="
              gender === 'female'
                ? !version18
                  ? '@/assets/lp-girl.webp'
                  : '@/assets/lpgirl18.webp'
                : '@/assets/lp-man.webp'
            "
            alt="Picture of person"
            class="aspect-square rounded-2xl"
            loading="lazy"
          />
        </picture>
      </div>
    </div>

    <ButtonComponent
      class="flex justify-center items-center h-14 md:h-16 w-72 font-bold text-lg gap-2 mx-auto"
      :to="redirectTo"
      @click.stop="redirect"
      ><img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
      {{ ctaPhrase }}</ButtonComponent
    >

    <div>
      <h2 class="text-2xl lg:text-3xl font-bold tracking-tighter pb-[25px]">
        Frequently Asked Questions
      </h2>
      <div
        class="faq-container lg:border border-white border-opacity-5 lg:p-[15px] lg:bg-[#0A0D1E] rounded-[15px]"
      >
        <div>
          <div
            v-for="faq in faqs"
            :key="faq.question"
            class="[&:not(:last-child)]:mb-[15px] lg:[&:not(:last-child)]:mb-5 bg-[#111026] px-[18px] lg:px-[28px] rounded-[12px] border border-[#1C1B38]"
          >
            <div
              class="flex gap-8 items-center py-[15px] lg:py-[28px] cursor-pointer w-full"
              @click="toggleFaq(faq)"
            >
              <h3 class="font-medium text-white w-full">
                {{ faq.question }}
              </h3>
              <img v-if="!faqOpen[faq.question]" :src="plusCircle" />
              <img class="h-[26px] w-[26px]" v-else :src="minus" />
            </div>
            <p
              class="pb-4"
              :class="{ hidden: !faqOpen[faq.question] }"
              v-html="faq.answer"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <ButtonComponent
      class="flex justify-center items-center h-14 md:h-16 w-72 font-bold text-lg gap-2 mx-auto"
      :to="redirectTo"
      @click.stop="redirect"
      ><img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
      {{ ctaPhrase }}</ButtonComponent
    >

    <FooterComponent></FooterComponent>
  </div>
</template>
