const names = {
  female: {
    caucasian: [
      'Emma',
      'Olivia',
      'Ava',
      'Isabella',
      'Sophia',
      'Mia',
      'Charlotte',
      'Amelia',
      'Harper',
      'Evelyn',
      'Abigail',
      'Emily',
      'Elizabeth',
      'Mila',
      'Ella',
      'Avery',
      'Sofia',
      'Camila',
      'Aria',
      'Scarlett',
      'Victoria',
      'Madison',
      'Luna',
      'Grace',
      'Chloe',
      'Penelope',
      'Layla',
      'Riley',
      'Zoey',
      'Nora',
      'Lily',
      'Eleanor',
      'Hannah',
      'Lillian',
      'Addison',
      'Aubrey',
      'Ellie',
      'Stella',
      'Natalie',
      'Zoe',
      'Leah',
      'Hazel',
      'Violet',
      'Aurora',
      'Savannah',
      'Audrey',
      'Brooklyn',
      'Bella',
      'Claire',
      'Skylar',
      'Lucy',
      'Paisley',
      'Everly',
      'Anna',
      'Caroline',
      'Nova',
      'Genesis',
      'Emilia',
      'Kennedy',
      'Samantha',
      'Maya',
      'Willow',
      'Kinsley',
      'Naomi',
      'Aaliyah',
      'Elena',
      'Sarah',
      'Ariana',
      'Allison',
      'Gabriella',
      'Alice',
      'Madelyn',
      'Cora',
      'Ruby',
      'Eva',
      'Serenity',
      'Autumn',
      'Adeline',
      'Hailey',
      'Gianna',
      'Valentina',
      'Isla',
      'Eliana',
      'Quinn',
      'Nevaeh',
      'Ivy',
      'Sadie',
      'Piper',
      'Lydia',
      'Alexa',
      'Josephine',
      'Emery',
      'Julia',
      'Delilah',
      'Arianna',
      'Vivian',
      'Kaylee',
      'Sophie',
      'Brielle',
      'Madeline',
    ],
    asian: [
      'Aiko',
      'Akira',
      'Amaya',
      'An',
      'Aya',
      'Chen',
      'Chiyo',
      'Cho',
      'Chun',
      'Daiyu',
      'Emi',
      'Fumiko',
      'Hana',
      'Haru',
      'Haruko',
      'Hina',
      'Hiroko',
      'Hitomi',
      'Hoshi',
      'Hotaru',
      'Izumi',
      'Jia',
      'Jin',
      'Jun',
      'Kaede',
      'Kai',
      'Kameko',
      'Kasumi',
      'Katsumi',
      'Keiko',
      'Kiko',
      'Kimiko',
      'Kumiko',
      'Kyoko',
      'Lan',
      'Lei',
      'Lian',
      'Liling',
      'Lin',
      'Ling',
      'Mai',
      'Masumi',
      'Mei',
      'Mieko',
      'Miki',
      'Min',
      'Ming',
      'Mio',
      'Miyako',
      'Miyu',
      'Naoko',
      'Nara',
      'Natsuki',
      'Noriko',
      'Nozomi',
      'Ran',
      'Rei',
      'Ren',
      'Riko',
      'Rina',
      'Rin',
      'Sachiko',
      'Sakura',
      'Sato',
      'Satoko',
      'Sawako',
      'Sayuri',
      'Shinju',
      'Shiori',
      'Suki',
      'Sumiko',
      'Sun',
      'Suzume',
      'Takako',
      'Tamiko',
      'Tanaka',
      'Ting',
      'Tomiko',
      'Umeko',
      'Uta',
      'Wang',
      'Wei',
      'Xia',
      'Xiu',
      'Yamato',
      'Yan',
      'Yasuko',
      'Yoko',
      'Yoshi',
      'Yoshiko',
      'Yu',
      'Yuan',
      'Yue',
      'Yui',
      'Yuki',
      'Yumi',
      'Yumiko',
      'Yuna',
      'Yuri',
      'Zhen',
      'Ai',
      'Airi',
      'Asami',
      'Ayaka',
      'Ayame',
      'Azumi',
      'Bao',
      'Bing',
      'Chie',
      'Chinatsu',
      'Chisato',
      'Dai',
      'Eiko',
      'Eriko',
      'Fai',
      'Fujiko',
      'Fumi',
      'Hatsumi',
      'Hikari',
      'Himari',
      'Hisa',
      'Hoshiko',
      'Ichika',
      'Jiao',
      'Jing',
      'Kaori',
      'Kasumi',
      'Kazuko',
      'Kei',
      'Kohana',
      'Kotone',
      'Kuniko',
      'Lei',
      'Lijuan',
      'Lina',
      'Mako',
      'Mana',
      'Manami',
      'Mao',
      'Mari',
      'Mariko',
      'Mayumi',
      'Michiko',
      'Midori',
      'Misaki',
      'Momoka',
      'Nao',
      'Narumi',
      'Natsumi',
      'Ning',
      'Noa',
      'Rika',
      'Rin',
      'Risa',
      'Sachiko',
      'Saki',
      'Sakura',
      'Saori',
      'Shizuka',
      'Sora',
      'Sumire',
      'Suzuki',
      'Takara',
      'Tomoe',
      'Tsubasa',
      'Tsukiko',
      'Wakana',
      'Xiulan',
      'Yoshie',
      'Yua',
      'Yuka',
      'Yuko',
      'Yuria',
      'Zixin',
    ],
    arabic: [
      'Aaliyah',
      'Aisha',
      'Amal',
      'Amani',
      'Amira',
      'Anisa',
      'Aya',
      'Aziza',
      'Basma',
      'Batul',
      'Bushra',
      'Dalia',
      'Dalila',
      'Dana',
      'Dania',
      'Dima',
      'Eman',
      'Esra',
      'Farah',
      'Fatima',
      'Fayza',
      'Ghada',
      'Habiba',
      'Hafsa',
      'Hala',
      'Halima',
      'Hanan',
      'Haniya',
      'Hasna',
      'Heba',
      'Hiba',
      'Huda',
      'Iman',
      'Inaya',
      'Isra',
      'Jalila',
      'Jamila',
      'Jannah',
      'Jumanah',
      'Kamila',
      'Karima',
      'Khadija',
      'Laila',
      'Lamia',
      'Leila',
      'Lina',
      'Maha',
      'Mahira',
      'Maisa',
      'Malak',
      'Manal',
      'Mariam',
      'Marwa',
      'Maya',
      'Mona',
      'Nadia',
      'Nadine',
      'Nahla',
      'Naima',
      'Najwa',
      'Nasreen',
      'Nawal',
      'Nayla',
      'Nour',
      'Noor',
      'Nuha',
      'Rabia',
      'Rahma',
      'Raja',
      'Rania',
      'Rasha',
      'Rawan',
      'Reem',
      'Rima',
      'Saba',
      'Sabiha',
      'Sadia',
      'Safaa',
      'Safiya',
      'Sahar',
      'Sahra',
      'Salma',
      'Samar',
      'Samira',
      'Sana',
      'Sara',
      'Sarah',
      'Selma',
      'Shadia',
      'Shakira',
      'Siham',
      'Soraya',
      'Sumaya',
      'Tahira',
      'Tala',
      'Tamara',
      'Thana',
      'Thara',
      'Wafa',
      'Yasmin',
      'Yusra',
      'Zahra',
      'Zainab',
      'Zakia',
      'Zara',
    ],
    indian: [
      'Aarohi',
      'Aarti',
      'Aditi',
      'Aishwarya',
      'Akanksha',
      'Akshara',
      'Amrita',
      'Ananya',
      'Anika',
      'Anjali',
      'Ankita',
      'Anushka',
      'Aparna',
      'Aradhana',
      'Asha',
      'Ashwini',
      'Avni',
      'Bhavna',
      'Chandni',
      'Charu',
      'Damini',
      'Deepa',
      'Deepika',
      'Dipti',
      'Divya',
      'Ekta',
      'Esha',
      'Gargi',
      'Garima',
      'Gauri',
      'Gayatri',
      'Geeta',
      'Gitanjali',
      'Harini',
      'Hema',
      'Indira',
      'Isha',
      'Ishita',
      'Janki',
      'Jaya',
      'Jayanti',
      'Juhi',
      'Kajal',
      'Kamala',
      'Kanchan',
      'Kavita',
      'Kiran',
      'Komala',
      'Krishna',
      'Kritika',
      'Kumud',
      'Lakshmi',
      'Lata',
      'Madhavi',
      'Madhu',
      'Madhuri',
      'Mala',
      'Mamta',
      'Manisha',
      'Meena',
      'Meera',
      'Megha',
      'Mina',
      'Mira',
      'Mohini',
      'Mukta',
      'Naina',
      'Namita',
      'Nandini',
      'Neha',
      'Neelam',
      'Nidhi',
      'Nikita',
      'Nina',
      'Nisha',
      'Pallavi',
      'Pooja',
      'Prachi',
      'Pragya',
      'Pratibha',
      'Priya',
      'Priyanka',
      'Purnima',
      'Radha',
      'Ragini',
      'Rakhi',
      'Rani',
      'Rashi',
      'Reena',
      'Rekha',
      'Renu',
      'Rina',
      'Ritu',
      'Rohini',
      'Sakshi',
      'Sangeeta',
      'Sanjana',
      'Sarita',
      'Seema',
      'Shalini',
      'Shanti',
      'Shashi',
      'Sheela',
      'Shilpa',
      'Shivani',
      'Shreya',
      'Smita',
      'Sonal',
      'Sonia',
      'Sunita',
    ],
    latina: [
      'Adriana',
      'Alejandra',
      'Alicia',
      'Alma',
      'Ana',
      'Andrea',
      'Angela',
      'Anita',
      'Antonia',
      'Ariadna',
      'Beatriz',
      'Belen',
      'Camila',
      'Carla',
      'Carmen',
      'Carolina',
      'Catalina',
      'Cecilia',
      'Clara',
      'Claudia',
      'Consuelo',
      'Cristina',
      'Daniela',
      'Diana',
      'Dolores',
      'Elena',
      'Elisa',
      'Elizabeth',
      'Elsa',
      'Emilia',
      'Esmeralda',
      'Estela',
      'Esther',
      'Eva',
      'Fabiola',
      'Felicia',
      'Fernanda',
      'Flor',
      'Francisca',
      'Gabriela',
      'Gloria',
      'Graciela',
      'Guadalupe',
      'Ines',
      'Irene',
      'Isabel',
      'Jacinta',
      'Jimena',
      'Josefina',
      'Juana',
      'Julia',
      'Karina',
      'Larissa',
      'Laura',
      'Leticia',
      'Liliana',
      'Lola',
      'Lucia',
      'Lucrecia',
      'Luisa',
      'Luz',
      'Magdalena',
      'Manuela',
      'Margarita',
      'Maria',
      'Mariana',
      'Maribel',
      'Marisol',
      'Marta',
      'Maya',
      'Mercedes',
      'Micaela',
      'Milagros',
      'Miranda',
      'Monica',
      'Nadia',
      'Natalia',
      'Nora',
      'Olivia',
      'Paloma',
      'Patricia',
      'Paula',
      'Paulina',
      'Pilar',
      'Raquel',
      'Rebeca',
      'Rita',
      'Rosa',
      'Rosario',
      'Salome',
      'Sandra',
      'Sara',
      'Selena',
      'Silvia',
      'Sofia',
      'Sonia',
      'Susana',
      'Tamara',
      'Tatiana',
      'Teresa',
      'Valentina',
      'Valeria',
      'Vanessa',
      'Veronica',
      'Victoria',
      'Violeta',
      'Virginia',
      'Ximena',
      'Yolanda',
      'Zoe',
    ],
    african: [
      'Aba',
      'Abena',
      'Abeo',
      'Achieng',
      'Adama',
      'Aida',
      'Aisha',
      'Akosua',
      'Ama',
      'Amara',
      'Amina',
      'Anaya',
      'Asisat',
      'Ayana',
      'Aziza',
      'Binta',
      'Chiamaka',
      'Chidinma',
      'Chinwe',
      'Dalila',
      'Deka',
      'Efia',
      'Esi',
      'Eshe',
      'Faduma',
      'Fatou',
      'Folake',
      'Halima',
      'Hasana',
      'Hawa',
      'Idowu',
      'Ifunanya',
      'Imani',
      'Isioma',
      'Jahzara',
      'Jamila',
      'Jendayi',
      'Kadiatou',
      'Kamaria',
      'Kehinde',
      'Khadija',
      'Kioni',
      'Laila',
      'Lerato',
      'Lulu',
      'Makena',
      'Mandisa',
      'Mariama',
      'Marjani',
      'Mawarire',
      'Mayowa',
      'Mbali',
      'Mojisola',
      'Mpho',
      'Msia',
      'Nadia',
      'Nailah',
      'Nala',
      'Nana',
      'Nandi',
      'Nene',
      'Ngina',
      'Nia',
      'Nina',
      'Nneka',
      'Noluthando',
      'Nomzamo',
      'Nontle',
      'Nosizwe',
      'Nthabiseng',
      'Ntombi',
      'Nyasha',
      'Ogechi',
      'Olabisi',
      'Omolara',
      'Onyeka',
      'Palesa',
      'Phindile',
      'Rafiki',
      'Rashida',
      'Rudo',
      'Sade',
      'Safiya',
      'Salama',
      'Samira',
      'Sanaa',
      'Sarafina',
      'Sena',
      'Shani',
      'Simisola',
      'Subira',
      'Tandie',
      'Tapiwa',
      'Taraji',
      'Temi',
      'Thandi',
      'Thembeka',
      'Tinotenda',
      'Titilayo',
      'Tsholofelo',
      'Unathi',
      'Wangari',
      'Yaa',
      'Yasmin',
      'Yewande',
      'Zahra',
      'Zainab',
      'Zalika',
      'Zanele',
      'Zara',
    ],
  },
  male: {
    caucasian: [
      'Adam',
      'Aiden',
      'Alexander',
      'Andrew',
      'Anthony',
      'Austin',
      'Benjamin',
      'Blake',
      'Brandon',
      'Brian',
      'Caleb',
      'Cameron',
      'Charles',
      'Christian',
      'Christopher',
      'Cole',
      'Connor',
      'Daniel',
      'David',
      'Derek',
      'Dylan',
      'Elijah',
      'Ethan',
      'Evan',
      'Gabriel',
      'Gavin',
      'Grant',
      'Henry',
      'Hunter',
      'Ian',
      'Isaac',
      'Jack',
      'Jackson',
      'Jacob',
      'James',
      'Jason',
      'Jeremy',
      'Jesse',
      'John',
      'Jonathan',
      'Jordan',
      'Joseph',
      'Joshua',
      'Julian',
      'Justin',
      'Keith',
      'Kevin',
      'Kyle',
      'Landon',
      'Liam',
      'Logan',
      'Lucas',
      'Luke',
      'Mason',
      'Matthew',
      'Max',
      'Michael',
      'Nathan',
      'Nicholas',
      'Noah',
      'Oliver',
      'Owen',
      'Parker',
      'Patrick',
      'Peter',
      'Ryan',
      'Samuel',
      'Scott',
      'Sean',
      'Sebastian',
      'Seth',
      'Shane',
      'Simon',
      'Spencer',
      'Stephen',
      'Taylor',
      'Thomas',
      'Timothy',
      'Travis',
      'Trevor',
      'Tyler',
      'Vincent',
      'William',
      'Wyatt',
      'Zachary',
      'Alec',
      'Bradley',
      'Bryce',
      'Carter',
      'Casey',
      'Chase',
      'Cody',
      'Colin',
      'Dakota',
      'Dalton',
      'Garrett',
      'George',
      'Grayson',
      'Jared',
      'Joel',
      'Jonah',
      'Jose',
      'Levi',
      'Marcus',
      'Mark',
    ],
    asian: [
      'Akio',
      'An',
      'Arun',
      'Asim',
      'Bao',
      'Chen',
      'Chi',
      'Chien',
      'Daisuke',
      'Deepak',
      'Dinesh',
      'Feng',
      'Ganesh',
      'Hao',
      'Haru',
      'Hideo',
      'Hiroshi',
      'Hung',
      'Jae',
      'Jin',
      'Jiro',
      'Jun',
      'Kang',
      'Kenji',
      'Khanh',
      'Kiet',
      'Koji',
      'Kun',
      'Lakshmi',
      'Lei',
      'Liang',
      'Lok',
      'Long',
      'Manh',
      'Ming',
      'Minh',
      'Narong',
      'Nhan',
      'Nikhil',
      'Nobu',
      'Park',
      'Phong',
      'Pranav',
      'Quan',
      'Raj',
      'Rajesh',
      'Ravi',
      'Ren',
      'Rohit',
      'Ryu',
      'Sanjay',
      'Satoshi',
      'Seiji',
      'Shan',
      'Shin',
      'Sho',
      'Siddharth',
      'Sohail',
      'Suresh',
      'Tadashi',
      'Takashi',
      'Takeshi',
      'Tan',
      'Taro',
      'Thanh',
      'Thien',
      'Tin',
      'Tomo',
      'Toshi',
      'Trung',
      'Vijay',
      'Wei',
      'Wen',
      'Xiang',
      'Xiao',
      'Yamato',
      'Yang',
      'Yasushi',
      'Yen',
      'Yi',
      'Yoichi',
      'Yong',
      'Yoshi',
      'Yuki',
      'Yusuke',
      'Zhen',
      'Zhong',
      'Zhou',
      'Anil',
      'Amit',
      'Ashok',
      'Aslam',
      'Aziz',
      'Balraj',
      'Bijoy',
      'Chandan',
      'Dilip',
      'Gopal',
      'Hari',
      'Imran',
      'Iqbal',
      'Javed',
      'Kamal',
      'Karan',
      'Lalit',
      'Aizen',
      'Akihiko',
      'Arata',
      'Bishamon',
      'Daichi',
      'Daigo',
      'Eijiro',
      'Eren',
      'Fuyuki',
      'Gaku',
      'Gen',
      'Gin',
      'Goro',
      'Hajime',
      'Hikaru',
      'Hinata',
      'Hiro',
      'Ichigo',
      'Isamu',
      'Itsuki',
      'Jiro',
      'Kai',
      'Kaito',
      'Kazuya',
      'Keiji',
      'Keita',
      'Kenshin',
      'Kenta',
      'Kiyoshi',
      'Kou',
      'Kouta',
      'Kunihiko',
      'Masashi',
      'Masato',
      'Mikio',
      'Minato',
      'Mitsuo',
      'Naoki',
      'Natsuki',
      'Nori',
      'Osamu',
      'Raito',
      'Reiji',
      'Renji',
      'Riku',
      'Rin',
      'Ryo',
      'Ryota',
      'Ryuu',
      'Saburo',
      'Sasuke',
      'Shinichi',
      'Shinji',
      'Shiro',
      'Shohei',
      'Shou',
      'Shouta',
      'Sora',
      'Souma',
      'Subaru',
      'Susumu',
      'Taichi',
      'Taiki',
      'Takao',
      'Takumi',
      'Taro',
      'Tatsuo',
      'Teppei',
      'Tetsuya',
      'Tomoya',
      'Toshiro',
      'Touma',
      'Tsuyoshi',
      'Umi',
      'Wataru',
      'Yamato',
      'Yasuo',
      'Yoshiaki',
      'Yoshio',
      'Yuji',
      'Yuki',
      'Yuma',
      'Yusaku',
      'Yuuto',
      'Zen',
      'Akira',
      'Byakuya',
      'Daisuke',
      'Eikichi',
      'Fukashi',
      'Hayate',
      'Hideo',
      'Kazuma',
      'Kiyotaka',
      'Kurama',
      'Makoto',
      'Ranmaru',
      'Setsuna',
      'Takeru',
      'Tokio',
      'Yorick',
    ],
    arabic: [
      'Abbas',
      'Abdallah',
      'Abdel',
      'Abdul',
      'Abdulaziz',
      'Abdulkarim',
      'Abdullah',
      'Abdulrahman',
      'Abid',
      'Adel',
      'Adnan',
      'Ahmad',
      'Ahmed',
      'Akram',
      'Ali',
      'Ameer',
      'Amin',
      'Amir',
      'Amjad',
      'Anas',
      'Anwar',
      'Arif',
      'Asad',
      'Ashraf',
      'Ayman',
      'Aziz',
      'Badr',
      'Bilal',
      'Bishr',
      'Dawood',
      'Emad',
      'Fadi',
      'Fahad',
      'Fahd',
      'Faisal',
      'Farid',
      'Faris',
      'Fathi',
      'Fawaz',
      'Fayez',
      'Faysal',
      'Fuad',
      'Ghassan',
      'Habib',
      'Hadi',
      'Hakim',
      'Halim',
      'Hamad',
      'Hamed',
      'Hameed',
      'Hamid',
      'Hamza',
      'Hani',
      'Harith',
      'Hasan',
      'Hashim',
      'Hassan',
      'Hatim',
      'Haytham',
      'Hazem',
      'Hisham',
      'Hosni',
      'Husam',
      'Husayn',
      'Hussain',
      'Hussein',
      'Ibrahim',
      'Idris',
      'Imad',
      'Imran',
      'Ismail',
      'Issa',
      'Jaber',
      'Jalal',
      'Jamaal',
      'Jamal',
      'Jawad',
      'Jibril',
      'Kader',
      'Kamal',
      'Karim',
      'Khaled',
      'Khalid',
      'Khalil',
      'Mahdi',
      'Maher',
      'Mahmood',
      'Mahmoud',
      'Majed',
      'Majid',
      'Mamdouh',
      'Marwan',
      'Mazen',
      'Mohamad',
      'Mohamed',
      'Mohammad',
      'Mohammed',
      'Mosa',
      'Moussa',
      'Moustafa',
      'Muhammad',
      'Muhannad',
      'Musa',
      'Musab',
      'Mustafa',
      'Nabil',
      'Nader',
      'Nadir',
      'Najeeb',
      'Najib',
    ],
    indian: [
      'Aarav',
      'Abhay',
      'Abhijit',
      'Aditya',
      'Ajay',
      'Ajit',
      'Akash',
      'Alok',
      'Amar',
      'Amir',
      'Amit',
      'Amrit',
      'Anand',
      'Anil',
      'Anish',
      'Ankit',
      'Ankur',
      'Arjun',
      'Ashok',
      'Ashwin',
      'Avinash',
      'Balraj',
      'Bharat',
      'Bhaskar',
      'Brijesh',
      'Chandan',
      'Chetan',
      'Deepak',
      'Dhruv',
      'Dinesh',
      'Divyansh',
      'Ganesh',
      'Gaurav',
      'Gopal',
      'Govind',
      'Harish',
      'Harsh',
      'Hemant',
      'Himanshu',
      'Inder',
      'Ishan',
      'Jai',
      'Jayesh',
      'Jeet',
      'Kailash',
      'Kamal',
      'Karan',
      'Kartik',
      'Kiran',
      'Kishan',
      'Krishna',
      'Kunal',
      'Lakshman',
      'Lalit',
      'Madhav',
      'Mahesh',
      'Manav',
      'Manish',
      'Manoj',
      'Mayank',
      'Mohan',
      'Mukesh',
      'Nandan',
      'Naveen',
      'Nikhil',
      'Nilesh',
      'Nitin',
      'Om',
      'Pankaj',
      'Pradeep',
      'Prakash',
      'Pramod',
      'Pranav',
      'Prasad',
      'Prateek',
      'Prem',
      'Puneet',
      'Purushottam',
      'Rahul',
      'Raj',
      'Rajeev',
      'Rajesh',
      'Rakesh',
      'Ramesh',
      'Ranbir',
      'Ranjeet',
      'Ravi',
      'Ritesh',
      'Rohan',
      'Rohit',
      'Sachin',
      'Sameer',
      'Sanjay',
      'Santosh',
      'Saurabh',
      'Shakti',
      'Shankar',
      'Sharad',
      'Shiv',
      'Shivam',
      'Shyam',
      'Siddharth',
      'Sohail',
      'Sudhir',
      'Sujit',
      'Sumeet',
      'Sunil',
      'Suresh',
      'Tarun',
      'Uday',
      'Umesh',
      'Vijay',
      'Vinay',
      'Vineet',
      'Vishal',
      'Vishnu',
      'Vivek',
      'Yash',
      'Yogesh',
      'Yuvraj',
    ],
    latino: [
      'Alejandro',
      'Andres',
      'Antonio',
      'Arturo',
      'Benjamin',
      'Carlos',
      'Cesar',
      'Cristian',
      'Daniel',
      'David',
      'Diego',
      'Eduardo',
      'Emilio',
      'Enrique',
      'Esteban',
      'Federico',
      'Felipe',
      'Fernando',
      'Francisco',
      'Gabriel',
      'Gonzalo',
      'Guillermo',
      'Gustavo',
      'Hector',
      'Hugo',
      'Ignacio',
      'Ivan',
      'Javier',
      'Jesus',
      'Joaquin',
      'Jorge',
      'Jose',
      'Juan',
      'Julian',
      'Julio',
      'Leonardo',
      'Lorenzo',
      'Luis',
      'Manuel',
      'Marco',
      'Marcos',
      'Mario',
      'Martin',
      'Mateo',
      'Mauricio',
      'Miguel',
      'Nicolas',
      'Omar',
      'Orlando',
      'Oscar',
      'Pablo',
      'Patricio',
      'Pedro',
      'Rafael',
      'Ramiro',
      'Ramon',
      'Raul',
      'Ricardo',
      'Roberto',
      'Rodrigo',
      'Ruben',
      'Samuel',
      'Santiago',
      'Saul',
      'Sebastian',
      'Sergio',
      'Tomas',
      'Victor',
      'Vincent',
      'Yago',
      'Adrian',
      'Alan',
      'Alberto',
      'Alfonso',
      'Alonso',
      'Angel',
      'Armando',
      'Arnaldo',
      'Baltazar',
      'Bruno',
      'Camilo',
      'Claudio',
      'Clemente',
      'Cristobal',
      'Damian',
      'Dario',
      'Edgar',
      'Elias',
      'Ezequiel',
      'Facundo',
      'Felix',
      'Franco',
      'Gael',
      'German',
      'Gilberto',
      'Gregorio',
      'Horacio',
      'Isaac',
      'Israel',
      'Jairo',
      'Jeronimo',
      'Joel',
      'Jonas',
      'Josue',
      'Leandro',
      'Leonel',
      'Leopoldo',
      'Lucas',
      'Luciano',
      'Marcelo',
      'Matias',
      'Maximiliano',
      'Nestor',
      'Norberto',
      'Octavio',
    ],
    african: [
      'Abebe',
      'Abioye',
      'Ade',
      'Ademola',
      'Adisa',
      'Afia',
      'Akachi',
      'Akeem',
      'Amadi',
      'Amani',
      'Amare',
      'Ayo',
      'Babajide',
      'Barack',
      'Baraka',
      'Bayo',
      'Bolanle',
      'Bongani',
      'Chibuike',
      'Chidiebere',
      'Chike',
      'Chinua',
      'Chukwuma',
      'Daudi',
      'Denzel',
      'Emeka',
      'Enitan',
      'Femi',
      'Festus',
      'Folami',
      'Gakuru',
      'Gathii',
      'Gebre',
      'Githinji',
      'Habib',
      'Hamisi',
      'Idris',
      'Ifeanyi',
      'Igwe',
      'Ikechukwu',
      'Ishola',
      'Issa',
      'Jabari',
      'Jabulani',
      'Jafari',
      'Jelani',
      'Kamau',
      'Kanu',
      'Kato',
      'Kayin',
      'Kazembe',
      'Kehinde',
      'Kenan',
      'Kenyatta',
      'Kgosi',
      'Khalfani',
      'Kibwe',
      'Kofi',
      'Kwabena',
      'Kwame',
      'Kwasi',
      'Lanre',
      'Lekan',
      'Lemuel',
      'Madu',
      'Makalo',
      'Makena',
      'Mandla',
      'Mansa',
      'Mehari',
      'Misheck',
      'Mosi',
      'Mugo',
      'Muhammadu',
      'Mukhtar',
      'Musa',
      'Musoke',
      'Mustafa',
      'Mutombo',
      'Mwamba',
      'Mwangi',
      'Nabii',
      'Nalo',
      'Nelson',
      'Ngozi',
      'Njau',
      'Nkosi',
      'Nnamdi',
      'Nthanda',
      'Nuru',
      'Nyack',
      'Obafemi',
      'Obi',
      'Odion',
      'Okechukwu',
      'Olabode',
      'Olaniyi',
      'Oluwaseun',
      'Oluwatobi',
      'Onyeka',
      'Orji',
      'Osaze',
      'Osinachi',
      'Sefu',
      'Segun',
      'Sekani',
      'Senwele',
      'Simba',
      'Tafari',
      'Taiwo',
      'Tamir',
      'Taye',
      'Tendai',
      'Thabo',
      'Tumelo',
      'Uche',
      'Uzoma',
      'Yaw',
      'Yemi',
      'Zuberi',
    ],
  },
}

export default names
