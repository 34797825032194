<script setup>
import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useHead } from '@unhead/vue'
import logo from '@/assets/logo.svg'
import particles from '@/assets/img/auth-particles.svg'
import Confirm from '@/components/Confirm.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Complete Profile`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

const $axios = inject('axios')
const $route = useRoute()
const $loading = useLoading({
  canCancel: false,
})

const email = ref('')

const query = $route.query

const completeRegister = async () => {
  const loader = $loading.show()

  try {
    const response = await $axios.post('/auth/complete-register', {
      email: email.value,
      userId: query.userId,
    })

    if (response.request.responseURL !== response.config.url) {
      window.location.href = response.request.responseURL
    }
  } catch (e) {
    console.log(e)
  } finally {
    loader.hide()
  }
}
</script>

<template>
  <div class="w-full h-full">
    <img class="absolute bottom-0 left-0 w-full" :src="particles" />
    <Confirm
      :open="true"
      :closeButton="false"
      :showDefault="false"
      popupStyle="bg-[#0A0D22] lg:w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
      type=""
      @confirm="handleButtonClick"
      :buttonText="{ confirm: 'Continue' }"
    >
      <div class="flex flex-col items-center gap-[25px]">
        <img class="w-[148px] h-auto" :src="logo" />
        <p class="text-[30px] text-center font-bold">Complete your profile</p>
        <p class="text-center">Enter your email to finish your signup</p>
        <form @submit.prevent="completeRegister" class="w-full px-[1px]">
          <input
            id="email"
            v-model="email"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
          />
          <ButtonComponent class="w-full py-3 mt-5">Continue</ButtonComponent>
        </form>
      </div>
    </Confirm>
  </div>
</template>
